import {
    ATHLETE_SEARCH_EMAIL_LOADING,
    GET_ATHLETE_SEARCH_EMAIL,
    GET_ATHLETE_SEARCH_EMAIL_NOT_FOUND
} from './types';

import axios from 'axios';

export const athlete_search_email = (email) => dispatch => {
    dispatch ({
      type: ATHLETE_SEARCH_EMAIL_LOADING,
    });
  
    setTimeout (()=> {
      axios.get (`/api/users/check?email=${email}`)
      .then((res) => {
        dispatch ({
          type: GET_ATHLETE_SEARCH_EMAIL,
          payload: res.data
        });
      }).catch ((err) => {
        console.error (err);
        dispatch ({
          type: GET_ATHLETE_SEARCH_EMAIL_NOT_FOUND,
        })
      });
    }, 500)
}

export const athlete_clean_search_data = () => dispatch => {
    dispatch ({
        type: GET_ATHLETE_SEARCH_EMAIL,
        payload: null
    })
}