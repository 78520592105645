import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// my components
import Loading from '../common/Loading';

// my actions
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { account_clear_current } from '../../actions/accountActions';
import { user_logout } from '../../actions/authActions';

class DashboardNav extends Component {

  onLogOutClick (e) {
    e.preventDefault ();
    this.props.account_clear_current ();
    this.props.user_logout ();
  }

  render () {
    let { user, isAdmin } = this.props.auth;
    const { account, loading } = this.props.account;

    const { t } =this.props;

    let account_content;
    if (account === null || loading) {
      account_content = <Loading type="bubbles" height={'20%'} width={'20%'} />
    }

    else {
      account_content = (
        <div className="profile-section pt-3 pt-lg-0">
          <img
            className="profile-image mb-3 rounded-circle mx-auto"
            src={process.env.PUBLIC_URL + '/img/no-avatar-icon.png'} alt="image"
          />
          <br></br>
          <h3 className="text-white">{ account.name }</h3>
          {/* <br></br>
          <div className="font-20px text-white">{ account.bio }</div> */}
        </div>
      );
    }

    // TODO: add request to check if user manages a competition
    const admin_content = (
      <ul className="navbar-nav flex-column text-left">
        <li className="nav-item">
          <a
            className="navbar-brand text-white"
            href={process.env.REACT_APP_ADMIN_DASHBOARD}
          >
            <i className="fas fa-user-shield fa-fw mr-2"></i>{t ('admin_dashboard')}
          </a>
        </li>

        <br></br>
        <div className="profile-section">
          <hr className="theme-bg-light"></hr>
        </div>
      </ul>
    );

    const common_content = (
      <div></div>
    );

    return (
      <div className="sticky-top">
        <div className="header text-center">
          <nav className="navbar navbar-expand-lg navbar-dark main-purple-background">
            <button
              className="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navigation" aria-controls="navigation"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="profile-section">
              <hr className="theme-bg-light"></hr>
            </div>

            <div id="navigation" className="collapse navbar-collapse flex-column" >
              { account_content }

              <br></br>
              <div className="profile-section">
                <hr className="theme-bg-light"></hr>
              </div>

              {/* profile */}
              <ul className="navbar-nav flex-column text-left">
                <li className="nav-item">
                  <a className="navbar-brand text-white" href="/">
                    <i className="fas fa-user fa-fw mr-2"></i>{t ('profile')}
                  </a>
                </li>

                <br></br>
                <div className="profile-section">
                  <hr className="theme-bg-light"></hr>
                </div>
              </ul>

              {/* apps */}
              <ul className="navbar-nav flex-column text-left">
                <li className="nav-item">
                  <a className="navbar-brand text-white" href="/competitions">
                    <i className="fas fa-trophy fa-fw mr-2"></i>{t ('competitions')}
                  </a>
                </li>

                <br></br>
                <div className="profile-section">
                  <hr className="theme-bg-light"></hr>
                </div>
              </ul>

              {/* admin */}
              { (isAdmin) ? admin_content : common_content }

              {/* settings */}
              <ul className="navbar-nav flex-column text-left">
                <li className="nav-item">
                  <a className="navbar-brand text-white" href="/settings">
                    <i className="fas fa-cog fa-fw mr-2"></i>{t ('settings')}
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="navbar-brand text-white" href="/"
                    onClick={ this.onLogOutClick.bind (this) }>
                    <i className="fas fa-sign-out-alt fa-fw mr-2"></i>{t ('logout')}
                  </a>
                </li>
              </ul>

            </div>
          </nav>
        </div>
      </div>
    );
  }

}

DashboardNav.propTypes = {
  auth: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  user_logout: PropTypes.func.isRequired,
  account_clear_current: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  account: state.account
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps, { user_logout, account_clear_current }
  )
)
(DashboardNav);
