import PropTypes from 'prop-types';
import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

const ImageInput = ({
  name,
  disabled = false,
  setFile
}) => {
  const [local, setLocal] = useState (null);
  const fileInput = useRef (null);

  const { t } = useTranslation (); 

  const onChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      setFile (e.target.files[0]);
      setLocal (e.target.files[0].name);
    }
  }
  return (
    <div className="form-group w-100">
      <input
        type="file"
        name={ name }
        ref={ fileInput }
        onChange={onChange}
        style={{ display: "none" }}
      />

      <div className="d-flex flex-column justify-content-start align-items-center w-100">
        <button
          className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px"
          disabled={disabled}
          onClick={() => fileInput.current.click ()}
        >
          {t ('select_file')}
        </button>

        { local && <p className="mt-4 font-24px">{local}</p> }
      </div>
      
    </div>
  )
}

ImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired
};

export default ImageInput;
