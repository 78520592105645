import {
  GET_MEMBER_REGISTRATION,
  MEMBER_REGISTRATION_LOADING,
  MEMBER_REGISTRATION_SEARCH_EMAIL_LOADING, 
  GET_MEMBER_REGISTRATION_SEARCH_EMAIL,
  GET_MEMBER_REGISTRATION_SEARCH_EMAIL_NOT_FOUND
} from '../actions/types';

const initial_state = {
  member_registration: null,
  member_registrations_loading: false,
  member_email_data: {}
};

export default function (state = initial_state, action) {

  switch (action.type) {
    case MEMBER_REGISTRATION_LOADING:
    return {
      ...state,
      loading: true
    };
    case GET_MEMBER_REGISTRATION:
    return {
      ...state,
      member_registration: action.payload,
      loading: false
    };
    case MEMBER_REGISTRATION_SEARCH_EMAIL_LOADING:
      return {
        ...state,
        member_email_data: {
          ...state.member_email_data,
          [action.payload]: {
            loading: true,
            data: null,
            not_found: false
          }
        }
      }
    case GET_MEMBER_REGISTRATION_SEARCH_EMAIL:
      return {
        ...state,
        member_email_data: {
          ...state.member_email_data,
          [action.payload.idx]: {
            loading: false,
            data: action.payload.data,
            not_found: false
          }
        }
      }
    case GET_MEMBER_REGISTRATION_SEARCH_EMAIL_NOT_FOUND:
      return {
        ...state,
        member_email_data: {
          ...state.member_email_data,
          [action.payload.idx]: {
            loading: false,
            data: null,
            not_found: true
          }
        }
      }
    default: return state;
  }

}
