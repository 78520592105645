import axios from 'axios';

import {
  DIVISION_LOADING, GET_DIVISION
} from './types';

export const division_category_string = (category) => {

  let division_category = null;
  switch (category) {
    case 0: division_category = "None"; break;
    case 1: division_category = "Amateur"; break;
    case 2: division_category = "Starter"; break;
    case 3: division_category = "Medium"; break;
    case 4: division_category = "Advanced"; break;
    case 5: division_category = "Elite"; break;

    default: division_category = "Undefined!"; break;
  }

  return division_category;

}

export const division_get_info = (
  comp_id, division_id, session_token
) => dispatch => {

  const config = {};

  if (session_token !== null) {
    config["headers"] = { Authorization: `Bearer ${session_token}` }
  }

  dispatch (divisions_set_loading ());

  axios.get (`/api/competitions/${comp_id}/divisions/${division_id}/info`, config)
    .then (res =>
      dispatch ({
        type: GET_DIVISION,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_DIVISION,
        payload: null
      }));

}

export const divisions_set_loading = () => {
  return {
    type: DIVISION_LOADING
  };
};
