import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Footer from '../Footer';
import Navbar from '../Navbar';

import Loading from '../common/Loading';

import CompetitionItem from './CompetitionItem';
import DashboardNav from './DashboardNav';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { account_get } from '../../actions/accountActions';
import {
  competitions_get_all, COMPETITION_TYPE_EXTERNAL
} from '../../actions/competitionsActions';

class Header extends Component {

  render () {
    const { t } = this.props;

    return (
      <section className="gray-bg docs-header">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <div className="p-60px-b">
                <h1 className="main-purple font-w-700 font-60px md-font-40px sm-font-30px">
                  {t ('competitions')}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

Header.propTypes = {
  t: PropTypes.func.isRequired
}

class Competitions extends Component {

  constructor () {
    super ();
    this.state = {
      errors: {}
    }
  }

  componentDidMount () {
    this.props.account_get (null);
    this.props.competitions_get_all ();
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState ({ errors: nextProps.errors });
    }
  }

  render () {
    // let { errors } = this.state;

  const { account, loading } = this.props.account;
    const { competitions, competitions_loading } = this.props.competition;

    const { t } = this.props;

    let dashboard_content;
    if (
      (account === null || loading) || (competitions === null || competitions_loading)
    ) {
      dashboard_content = (
        <div className="container-fluid" align="center">
          <div className="projects-list px-3 py-5 p-md-5">
            <div id="down" className="projects-wrapper">
              <br></br>
              <Loading color="#80110f" height={'20%'} width={'20%'} />
            </div>
          </div>
        </div>
      );
    }

    else {
      // remove external competitions
      let competitions_filtered = competitions.filter (element => {
        if (element.competition_type != COMPETITION_TYPE_EXTERNAL) {
          return element;
        }
      });
      
      let competitions_feed = competitions_filtered.map (
        competition => <CompetitionItem key={competition._id["$oid"]} competition={competition} />
      );

      dashboard_content = (
        <div className="container">
    			<div className="py-5 mt-4 align-items-center">
            <div className="row">
              {competitions_feed}
            </div>
    			</div>
    		</div>
      );
    }

    return (
    <div>
      <Navbar />

      <DashboardNav />

      <div className="main-wrapper">
        <Header t={t}/>

        { dashboard_content }

        <br></br>

        <Footer />
      </div>
    </div>
    );
  }

}

Competitions.propTypes = {
  success: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  competition: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success,
  auth: state.auth,
  account: state.account,
  account_get: PropTypes.func.isRequired,
  competition: state.competition,
  competitions_get_all: PropTypes.func.isRequired
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    { account_get, competitions_get_all }
  )
) (withRouter (Competitions));
