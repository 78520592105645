import {
  GET_PHASE,
  GET_PHASE_NOT_FOUND,
  PHASE_LOADING,
} from '../actions/types';

const initial_state = {
  phase: null,
  phase_not_found: false,
  phases_loading: false
};

export default function (state = initial_state, action) {

  switch (action.type) {
    case PHASE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_PHASE:
      return {
        ...state,
        // phase: {
        //   ...action.payload,
        //   "pants_size": true,
        //   "bath_suit_type": true,
        //   "bath_suit_size": true
        // },
        phase: action.payload,
        phase_not_found: false,
        loading: false
      };
    case GET_PHASE_NOT_FOUND:
      return {
        ...state,
        phase: action.payload,
        phase_not_found : true,
        loading : false,
      }
    default: return state;
  }

}
