import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import version from '../version.json';

export default () => {

  const { t } = useTranslation ();
  
  return (
    <footer className="text-center page-footer dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <h5>Get started</h5>
            <ul>
              <li><a href="https://timecapp.app" target="_top">{t ('home')}</a></li>
              <li><Link to="/register" target="_top">{t ('sign_up')}</Link></li>
              <li><Link to="/login" target="_top">{t ('login')}</Link></li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h5>{t ('about')}</h5>
            <ul>
              <li><a href="https://timecapp.app" target="_top">{t ('about_us')}</a></li>
              <li><a href="https://timecapp.app" target="_top">{t ('contact_us')}</a></li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h5>{t ('support')}</h5>
            <ul>
              <li><a href="https://timecapp.app" target="_top">FAQ</a></li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h5>Legal</h5>
            <ul>
              <li><a href="https://timecapp.app/aviso-de-privacidad" target="_top">{t ('privacy_policy')}</a></li>
              <li><a href="https://timecapp.app/t%C3%A9rminos-y-condiciones" target="_top">{t ('terms_of_use')}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
      <p>Copyright &copy; { new Date ().getFullYear () } Bynd - { version.version_name } - { version.version_date }</p>
      </div>
    </footer>
  );

};
