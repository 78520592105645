import axios from 'axios';

import {
  REGISTRATION_LOADING, GET_REGISTRATION, ERRORS_GET,
  REGISTRATION_VALIDATING, GET_REGISTRATION_VALIDATION
} from './types';

// import { errors_clear } from './errorsActions';
// import { success_clear } from './successActions';

export const registration_status_string = (status) => {

  let registration_status = null;
  switch (status) {
    case 0: registration_status = "None"; break;
    case 1: registration_status = "Created"; break;
    case 2: registration_status = "Processing..."; break;
    case 3: registration_status = "Good!"; break;
    case 4: registration_status = "Bad!"; break;
    case 5: registration_status = "Cancelled!"; break;

    default: registration_status = "Undefined!"; break;
  }

  return registration_status;

}

export const registration_check = (competition) => dispatch => {

  dispatch (registrations_set_loading ());

  axios.get (
    "/api/registrations/check",
    { params: { competition: competition } }
  )
    .then (res =>
      dispatch ({
        type: GET_REGISTRATION,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_REGISTRATION,
        payload: null
      }));

}

export const registration_validate = (competition, phase, password) => dispatch => {
  dispatch ({
    type: REGISTRATION_VALIDATING
  })

  axios.get (
    `/api/registrations/phases/${phase}/validate`,
    { params: { 
      competition: competition,
      password: password
    } }
  )
    .then (_ =>
      dispatch ({
        type: GET_REGISTRATION_VALIDATION,
        payload: {
          value: true,
          error: null
        }
      }))
    .catch (err => {
      dispatch ({
        type: GET_REGISTRATION_VALIDATION,
        payload: {
          value: false,
          error: err.response.data !== undefined ? err.response.data.password : err.data
        }
      })
      
    }
  );
}

export const registrations_set_loading = () => {
  return {
    type: REGISTRATION_LOADING
  };
};
