import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Footer from '../../Footer';
import Navbar from '../../Navbar';

import DashboardNav from '../DashboardNav';

import Loading from '../../common/Loading';

import AthleteRegistration from './AthleteRegistration';
import CompetitionHeader from './CompetitionHeader';
import CompetitionInformation from './CompetitionInfo';
import CompetitionRegistration from './CompetitionRegistration';
import MemberRegistration from './MemberRegistration';
import TeamRegistration from './TeamRegistration';

import { account_get } from '../../../actions/accountActions';

import {
  competition_get_info
} from '../../../actions/competitionsActions';

import {
  registration_check
} from '../../../actions/registrationsActions';

import {
  member_registration_check
} from '../../../actions/membersActions';

import {
  team_registration_check
} from '../../../actions/teamsActions';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import isEmpty from '../../../utils/isEmpty';

class Competition extends Component {

  componentDidMount () {
    this.props.account_get (null);

    if (this.props.match.params.id) {
      // fetch general competition's information
      this.props.competition_get_info (this.props.match.params.id, null);

      // check for user's athlete registration information
      this.props.registration_check (this.props.match.params.id);

      // check for user's team registration information
      this.props.team_registration_check (this.props.match.params.id);

      // check for user's member registration information
      this.props.member_registration_check (this.props.match.params.id);
    }
  }

  componentWillReceiveProps (nextProps) {
    if (
      (nextProps.competition.competition === null)
      && this.props.competition.competitions_loading
      && !this.props.account.loading
    ) {
      this.props.history.push ('/404');
    }
  }

  render () {
    const { account, loading } = this.props.account;
    const { competition, competitions_loading } = this.props.competition;
    const { registration, registrations_loading } = this.props.registration;
    const { team_registration, team_registrations_loading } = this.props.team;
    const { member_registration, member_registrations_loading } = this.props.member;
    const { t } =this.props;

    let dashboard_content = null;
    let registration_information = null;

    let loading_content = (
      <div className="container-fluid" align="center">
        <div className="projects-list px-3 py-5 p-md-5">
          <div id="down" className="projects-wrapper">
            <br></br>
            <Loading color="#80110f" height={'20%'} width={'20%'} />
          </div>
        </div>
      </div>
    );

    if (
      (account === null || loading)
      || (competition === null || competitions_loading)
      || (registrations_loading)
      || (team_registrations_loading)
      || (member_registrations_loading)
    ) {
      dashboard_content = loading_content;
    }

    else {
      // registration
      if (!isEmpty (registration)) {
        registration_information = (
          <AthleteRegistration registration={registration}/>
        );
      }

      else if (!isEmpty (team_registration)) {
        registration_information = (
          <TeamRegistration team_registration={team_registration}/>
        );
      }

      else if (!isEmpty (member_registration)) {
        registration_information = (
          <MemberRegistration member_registration={member_registration}/>
        );
      }

      else {
        registration_information = (
          <CompetitionRegistration competition={competition}/>
        );
      }

      // main dashboard content
      if (!isEmpty (competition)) {
        dashboard_content = (
        <div>
        <CompetitionHeader competition={competition}/>

        <br></br>
        <br></br>

        <div className="container">
          <div className="align-items-center">
            <div className="text-center">
              <CompetitionInformation competition={competition}/>

              {/* divisions */}
              {/* <div className="col-8" align="left">
                <h2 className="main-red">Categories</h2>
              </div> */}

              <div className="col-8" align="left">
                <h2 className="main-red">{t ('your_register')}</h2>
              </div>

              { registration_information }

            </div>
          </div>
        </div>
        </div>
        );
      }

      else {
        dashboard_content = loading_content;
      }
    }

    return (
    <div>
      <Navbar />

      <DashboardNav />

      <div className="main-wrapper">
        { dashboard_content }

        <br></br>

        <Footer />
      </div>
    </div>
    );
  }

}

Competition.propTypes = {
  success: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  competition: PropTypes.object.isRequired,
  registration: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success,
  auth: state.auth,
  account: state.account,
  account_get: PropTypes.func.isRequired,
  checkout_session: PropTypes.func.isRequired,
  competition: state.competition,
  competition_get_info: PropTypes.func.isRequired,
  registration: state.registration,
  registration_check: PropTypes.func.isRequired,
  team: state.team,
  team_registration_check: PropTypes.func.isRequired,
  member: state.member,
  member_registration_check: PropTypes.func.isRequired
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    {
      account_get,
      competition_get_info,
      registration_check,
      team_registration_check,
      member_registration_check
    }
  ) 
)(withRouter (Competition));
