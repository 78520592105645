import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { account_get } from '../../../actions/accountActions';
import { alert_set } from '../../../actions/alertsActions';
import { user_session_token } from '../../../actions/authActions';
import { competition_get_info } from '../../../actions/competitionsActions';
import { phase_get_current } from '../../../actions/phasesActions';
import { registration_validate } from '../../../actions/registrationsActions';
import Loading from '../../common/Loading';
import Footer from '../../Footer';
import Navbar from '../../Navbar';
import DashboardNav from '../DashboardNav';
import CompetitionHeader from './CompetitionHeader';
import CompetitionInformation from './CompetitionInfo';

class CompetitionRegistrationPassword extends Component {
	constructor () {
		super ();
		this.state = {
			division: "",
			mobile: false,
			session_token: false,
			password: "",
			phase: "",
			validation: {
				loading: false,
				validated: false,
				error: null
			}
		}
		
		this.onChangePassword = this.onChangePassword.bind (this);
		this.requestRegistrationValidation = this.requestRegistrationValidation.bind (this);
		this.onReturnToCompetition = this.onReturnToCompetition.bind (this);
	}

	componentDidMount () {
		let competition = this.props.match.params.id;
		let params = new URLSearchParams (this.props.location.search);
		let division = params.get ('division');
		let session_token = params.get ('token');
		let mobile = params.get ("mobile");

		this.setState ({
			division: division,
			mobile: mobile === "True",
			session_token: session_token
		});

		// handle user session token
		this.props.user_session_token (session_token);

		// fetch the user's main information
		this.props.account_get (session_token);

		// fetch the selected division's information
		this.props.competition_get_info (competition, session_token);
		
		// fetch the competition's registration phases
		this.props.phase_get_current (competition, division, session_token);
	}

	componentWillReceiveProps (nextProps) {
		const {competition} = nextProps.competition;
		const {phase} = nextProps.phase;
		const {validation} = nextProps.registration;

		this.setState ({
			validation: nextProps.registration.validation
		})

		if (phase !== null && competition !== null) {
			this.setState ({
				phase: phase._id ["$oid"]
			});
			if (!phase.requires_password || validation.validated) {
				let division = null;
				let divisions = competition.divisions.filter (element => {
					if (this.state.division == element._id["$oid"]) {
					return element;
					}
				});
	
				if (divisions.length > 0) {
					division = divisions[0];
				}

				let params = new URLSearchParams (this.props.location.search);
				let session_token = params.get ("token");
				let mobile = params.get ("mobile");
	
				let base_path = `/competitions/${competition._id["$oid"]}/registration`;
	
				if (division.n_athletes > 1) {
				  base_path = base_path.concat ('/team');
				}

				let redirect = `${base_path}?division=${division._id["$oid"]}`;

				if (session_token !== null) {
					redirect += `&token=${session_token}`;
				}

				if (mobile !== null) {
					redirect += `&mobile=${mobile}`;
				}

				// console.log (redirect);
				window.location.href = redirect;
			} else if (nextProps.registration.validation.error !== null) {
				this.props.alert_set (nextProps.registration.validation.error, "danger");
			}

		}

	}

	onChangePassword (e) {
		this.setState ({
		"password": e.target.value
		});
	}

	requestRegistrationValidation (e) {
		e.preventDefault ();

		if (!this.state.validation.loading) {
			this.props.registration_validate (this.props.match.params.id, this.state.phase, this.state.password);
		}
	}

	onReturnToCompetition () {
		let competition = this.props.match.params.id;
		let redirect = `/competitions/${competition}/info`;
		if (this.state.mobile) {
			redirect = "timecapp://success";
		}
		window.location.href = redirect;
	}

	render () {
		const { validation } = this.props.registration;
		const { phases_loading, phase_not_found } = this.props.phase;
		const { competition, competitions_loading } = this.props.competition;

		const { t } =this.props;

		let loading_content = (
			<div className="container-fluid" align="center">
				<div className="projects-list px-3 py-5 p-md-5">
				<div id="down" className="projects-wrapper">
					<br></br>
					<Loading color="#80110f" height={'20%'} width={'20%'} />
				</div>
				</div>
			</div>
		);

		let searching_content = (
			<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
		);

		let phase_not_found_content = (
			<div className="container-fluid vh-100" align="center">
			  <div className="projects-list h-100 d-flex flex-column justify-content-center align-items-center">
				  <h2>{t ('no_register_phase')}</h2>
				  <p>{t ('try_again_later')}</p>
				  <button className='m-btn m-btn-theme m-btn-radius btn-lg w-75' onClick={() => this.onReturnToCompetition ()}>
					{t ('return')}
				  </button>
			  </div>
			</div>
		);


		let dashboard_content = null;

		if (phases_loading || (competition === null || competitions_loading)) {
			dashboard_content = loading_content;
		} else if (validation.validated) {
			dashboard_content =  (
				<div className='text-center h-100'>
					{t ('redirecting')} ...
				</div>
			);
		} else if (phase_not_found) {
			dashboard_content = phase_not_found_content;
		} 
		else {
			let validation_input = (
				<div className="mb-5">
				<div className="card competition-card bg-gray">
					<div className="row no-gutters">
						<div className="col-lg-12">
							<div className="card-body">
							<h5 align="left">{t ('enter_password')}</h5>

							<hr></hr>

							<div className="row">
								{/* division */}
								<label className="col-md-12" align="left">
									{t ('password')}
								</label>
								<div className="input-group col-lg-12 mb-4">
									<div className="col-md-12 text-center">
										<input className='form-control' type="text" name="password" onChange={this.onChangePassword} />
									</div>
								</div>
								<div className="form-group col-lg-4 mx-auto mb-0 text-center">
									<button
										className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px"
										onClick={this.requestRegistrationValidation}
									>
										{
											this.state.validation.loading
											? searching_content
											: t ('validate')
										}
									</button>
								</div>
							</div>
							
							</div>
						</div>
					</div>
				</div>
			</div>
			);

			dashboard_content =  (
				<div>
					<CompetitionHeader competition={competition}/>

					<br></br>
					<br></br>

					<div className="container">
					<div className="align-items-center">
						<div className="text-center">
						<CompetitionInformation competition={competition}/>

						{/* divisions */}
						{/* <div className="col-8" align="left">
							<h2 className="main-red">Categories</h2>
						</div> */}

						<div className="col-8" align="left">
							<h2 className="main-red">{t ('access_code')}</h2>
						</div>

						{ validation_input }

						</div>
					</div>
					</div>
				</div>
				
			);
		}

		return (
			<div>
				<Navbar />
		
				<DashboardNav />
		
				<div className="main-wrapper">
				{ dashboard_content }
		
				<br></br>
		
				<Footer />
				</div>
			</div>
		);
	}

}

const mapStateToProps = state => ({
	competition: state.competition,
	registration: state.registration,
	phase: state.phase
});

CompetitionRegistrationPassword.propTypes = {
	alert_set: PropTypes.func.isRequired,
	competition: PropTypes.object.isRequired
};

export default compose (
	withTranslation (),
	connect (
		mapStateToProps,
		{
			alert_set,
			account_get,
			competition_get_info,
			phase_get_current,
			registration_validate,
			user_session_token,
		}
	)
) (withRouter (CompetitionRegistrationPassword));
