import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import {
  division_category_string
} from '../../../actions/divisionsActions';

class Division extends Component {

  render () {
    let { division } = this.props;
    
    let division_category = division_category_string (division.category);

    const { t } = this.props;

    return (
    <div className="mb-5">
      <div className="card competition-card bg-gray">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="card-body">
              <h3>{ division.name }</h3>

              <h3 className='main-dark-blue'>{ division_category }</h3>

              <hr></hr>

              <div className="row">
                <div className="col-sm-4">
                  <h4><i className="fas fa-male main-dark-blue"></i></h4>
                  <p className="font-24px">
                    <strong>
                      {t ('male')}: { division.gender.n_persons_a }
                    </strong>
                  </p>
                </div>

                <div className="col-sm-4">
                  <h4><i className="fas fa-female main-dark-blue"></i></h4>
                  <p className="font-24px">
                    <strong>
                      {t ('female')}: { division.gender.n_persons_b }
                    </strong>
                  </p>
                </div>

                <div className="col-sm-4">
                  <h4><i className="fas fa-user main-dark-blue"></i></h4>
                  <p className="font-24px">
                    <strong>
                      {t ('free')}: { division.gender.free }
                    </strong>
                  </p>
                </div>

              </div>
              <hr></hr>
              <div className='row'>
                <div className='col-sm-6 text-center'>
                  <h5>{t ('minimum_age')}: {division.minimum_age}</h5>
                </div>  
                <div className='col-sm-6 text-center'>
                  <h5>{t ('maximum_age')}: {division.maximum_age}</h5>
                </div>  
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

}

Division.propTypes = {
  division: PropTypes.object.isRequired
};

export default withTranslation ()(Division);
