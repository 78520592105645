import {
    ATHLETE_SEARCH_EMAIL_LOADING,
    GET_ATHLETE_SEARCH_EMAIL,
    GET_ATHLETE_SEARCH_EMAIL_NOT_FOUND
} from '../actions/types';

const initialState = {
    "loading": false,
    "not_found": false,
    "data": null    
};


export default function athletesReducer (state = initialState, action) {
    switch (action.type) {
        case ATHLETE_SEARCH_EMAIL_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ATHLETE_SEARCH_EMAIL:
            return {
                ...state,
                loading: false,
                not_found: false,
                data: action.payload
            }
        case GET_ATHLETE_SEARCH_EMAIL_NOT_FOUND:
            return {
                ...state,
                loading: false,
                not_found: true,
                data: null,
            }
        default:
            return state;
    }
}