import axios from 'axios';

import {
  ERRORS_GET, SUCCESS_GET,
  GET_PROFILES, GET_PROFILE,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
} from './types';

import { errors_clear } from './errorsActions';
import { success_clear } from './successActions';
import { alert_set } from './alertsActions';

// fetch all users public profiles
export const profiles_get_all = () => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  dispatch (profile_set_loading ());

  axios.get ('/api/users/profiles')
    .then (res =>
      dispatch ({
        type: GET_PROFILES,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_PROFILES,
        payload: null
      }));

}

// get the user's public profile
export const profile_get_info = id => dispatch => {

  dispatch (profile_set_loading ());

  axios.get (`/api/users/${id}/profile`)
  .then (res =>
    dispatch ({
      type: GET_PROFILE,
      payload: res.data
    }))
  .catch (err =>
    dispatch ({
      type: GET_PROFILE,
      payload: null
    }));

};

export const profile_update = (data) => dispatch => {

  dispatch (errors_clear ());
	dispatch (success_clear ());

  axios.put ('/api/users/profile', data)
    .then (res => {
      dispatch (alert_set ('Profile values have been updated!', 'success'));
      dispatch ({
        type: SUCCESS_GET,
        payload: res.data
      });
    })
    .catch (err =>
      dispatch ({
        type: ERRORS_GET,
        payload: err.response.data
      }));

}

// profile loading
export const profile_set_loading = () => {
  return {
    type: PROFILE_LOADING
  };
};

// clear profile
export const profile_clear_current = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};
