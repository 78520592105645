import {
  GET_ACCOUNT,
  ACCOUNT_LOADING,
  CLEAR_CURRENT_ACCOUNT
} from '../actions/types';
  
const initial_state = {
  account: null,
  loading: false
};
  
export default function (state=initial_state, action) {
  switch (action.type) {
    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        loading: false
      };
    case CLEAR_CURRENT_ACCOUNT:
      return {
        ...state,
        account: null
      };

    default: return state;
  }
}
