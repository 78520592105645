import React, { Component } from 'react';

import PropTypes from 'prop-types';

class CompetitionHeader extends Component {

  render () {
    let { competition } = this.props;

    return (
      <section className="gray-bg docs-header">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <div className="p-60px-b">
                <h1 className="main-purple font-w-700 font-60px md-font-40px sm-font-30px">
                  {competition.name}
                </h1>

                <div className="col-12 text-center">
                  <img className="img-fluid" src={ `/api/uploads/competitions/${competition._id["$oid"]}/${competition.cover}` } alt="Competition cover image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

CompetitionHeader.propTypes = {
  competition: PropTypes.object.isRequired
};

export default CompetitionHeader;
