import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

class Authentication extends Component {

  componentDidMount () {
    process.env.REACT_APP_RUNTIME === "Production"
      ? window.location.href = "https://login.timecapp.app"
      : process.env.REACT_APP_RUNTIME === "Test"
        ? window.location.href = "http://testlogin.timecapp.app"
        : window.location.href = "http://login.localhost.com"
  }

  render () {
    const { t } = this.props;

    return (
      <div>
        <h1>{t ('redirecting')}...</h1>
      </div>
    );
  }

}

Authentication.propTypes = {};

const mapStateToProps = state => ({});

export default compose(
  withRouter,
  withTranslation (),
  connect (mapStateToProps, { })
)(Authentication);
