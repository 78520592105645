import { SUCCESS_CLEAR, SUCCESS_GET } from '../actions/types';

let initial_state = {};

export default function (state=initial_state, action) {

  switch (action.type) {
    case SUCCESS_CLEAR: return {};

    case SUCCESS_GET: return action.payload;

    default: return state;
  }

}
