import axios from 'axios';

import {
  MEMBER_REGISTRATION_LOADING, GET_MEMBER_REGISTRATION,
  MEMBER_REGISTRATION_SEARCH_EMAIL_LOADING,
  GET_MEMBER_REGISTRATION_SEARCH_EMAIL,
  GET_MEMBER_REGISTRATION_SEARCH_EMAIL_NOT_FOUND
} from './types';

// import { errors_clear } from './errorsActions';
// import { success_clear } from './successActions';

export const member_registration_check = (competition) => dispatch => {

  dispatch (member_registrations_set_loading ());

  axios.get (
    "/api/registrations/members/check",
    { params: { competition: competition } }
  )
    .then (res =>
      dispatch ({
        type: GET_MEMBER_REGISTRATION,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_MEMBER_REGISTRATION,
        payload: null
      }));

}

export const member_search_email = (email, idx) => dispatch => {
  dispatch ({
    type: MEMBER_REGISTRATION_SEARCH_EMAIL_LOADING,
    payload: idx
  })

  setTimeout (()=> {
    axios.get (`/api/users/check?email=${email}`)
    .then((res) => {
      dispatch ({
        type: GET_MEMBER_REGISTRATION_SEARCH_EMAIL,
        payload: {
          "idx": idx,
          "data": res.data
        }
      });
    }).catch ((err) => {
      console.error (err);
      dispatch ({
        type: GET_MEMBER_REGISTRATION_SEARCH_EMAIL_NOT_FOUND,
        payload: {
          "idx": idx
        }
      })
    });
  }, 500)
}

export const member_search_clean_data = (idx) => dispatch => {
  dispatch ({
    type: GET_MEMBER_REGISTRATION_SEARCH_EMAIL,
    payload: {
      "idx": idx,
      "data": null
    }
  });
}

export const member_registrations_set_loading = () => {
  return {
    type: MEMBER_REGISTRATION_LOADING
  };
};
