import {
  GET_DIVISION,
  DIVISION_LOADING,
} from '../actions/types';

const initial_state = {
  division: null,
  divisions_loading: false
};

export default function (state = initial_state, action) {

  switch (action.type) {
    case DIVISION_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_DIVISION:
      return {
        ...state,
        division: action.payload,
        loading: false
      };
    default: return state;
  }

}
