import axios from 'axios';

import {
  ERRORS_GET, SUCCESS_GET,
  COMPETITION_LOADING, GET_COMPETITIONS, GET_COMPETITION
} from './types';

import { errors_clear } from './errorsActions';
import { success_clear } from './successActions';

export const COMPETITION_TYPE_NONE = 0;
export const COMPETITION_TYPE_ONLINE = 1;
export const COMPETITION_TYPE_ON_SITE = 2;
export const COMPETITION_TYPE_EXTERNAL = 3;
export const COMPETITION_TYPE_CHALLENGE = 4;

export const COMPETITION_STATUS_NONE	= 0;
export const COMPETITION_STATUS_PUBLISHED = 1;
export const COMPETITION_STATUS_AVAILABLE = 2;
export const COMPETITION_STATUS_PROGRESS = 3;
export const COMPETITION_STATUS_ENDED = 4;

export const competition_type_string = (type) => {

  let competition_type = null;
  switch (type) {
    case COMPETITION_TYPE_NONE: competition_type = "None"; break;
    case COMPETITION_TYPE_ONLINE: competition_type = "Online"; break;
    case COMPETITION_TYPE_ON_SITE: competition_type = "On-Site"; break;
    case COMPETITION_TYPE_EXTERNAL: competition_type = "External"; break;
    case COMPETITION_TYPE_CHALLENGE: competition_type = "Challenge"; break;

    default: competition_type = "Undefined"; break;
  }

  return competition_type;

}

export const competition_status_string = (status) => {

  let competition_status = null;
  switch (status) {
    case COMPETITION_STATUS_NONE: competition_status = "None"; break;
    case COMPETITION_STATUS_PUBLISHED: competition_status = "Coming soon..."; break;
    case COMPETITION_STATUS_AVAILABLE: competition_status = "Available"; break;
    case COMPETITION_STATUS_PROGRESS: competition_status = "In Progress"; break;
    case COMPETITION_STATUS_ENDED: competition_status = "Ended"; break;

    default: competition_status = "Undefined!"; break;
  }

  return competition_status;

}

// fetch all competitions
export const competitions_get_all = () => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  dispatch (competitions_set_loading ());

  axios.get (`/api/competitions?status=${COMPETITION_STATUS_AVAILABLE}`)
    .then (res =>
      dispatch ({
        type: GET_COMPETITIONS,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_COMPETITIONS,
        payload: null
      }));

}

export const competition_get_info = (id, session_token) => dispatch => {

  const config = {};

  if (session_token !== null) {
    config["headers"] = { Authorization: `Bearer ${session_token}` }
  }

  dispatch (competitions_set_loading ());

  axios.get (`/api/competitions/${id}/complete`, config)
    .then (res =>
      dispatch ({
        type: GET_COMPETITION,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_COMPETITION,
        payload: null
      }));

}

export const competitions_set_loading = () => {
  return {
    type: COMPETITION_LOADING
  };
};
