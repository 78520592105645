import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { alert_set } from '../../../actions/alertsActions';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import isEmpty from '../../../utils/isEmpty';

class CompetitionRegistration extends Component {

  constructor () {
    super ();

    this.state = {
      division: '',
    }

    this.onSelect = this.onSelect.bind (this);
    this.requestRegistration = this.requestRegistration.bind (this);
  }

  onSelect (option) {
    // console.log ("Selected division ", option.label);
    this.setState ({division: option});
  }

  requestRegistration (e) {
    e.preventDefault ();

    const { competition } = this.props;

    const { t } =this.props;

    if (!isEmpty (this.state.division)) {
      let division = null;
      let divisions = competition.divisions.filter (element => {
        if (this.state.division.value == element._id["$oid"]) {
          return element;
        }
      });

      if (divisions.length > 0) {
        division = divisions[0];
      }

      let base_path = `/competitions/${competition._id["$oid"]}/registration/password`;

      // if (division.n_athletes > 1) {
      //   base_path = base_path.concat ('/team');
      // }

      let redirect = `${base_path}?division=${division._id["$oid"]}`;
      // console.log (redirect);
      window.location.href = redirect;
    }

    else {
      this.props.alert_set (t ('select_a_category'), "danger");
    }
  }

	render () {
    let { competition } = this.props;
    const { t } =this.props;

    const divisions = [];
    competition.divisions.forEach (element => {
      divisions.push ({
        value: element._id["$oid"],
        label: element.name
      })
    });

    return (
    <div className="mb-5">
      <div className="card competition-card bg-gray">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="card-body">
              <h5 align="left">{t ('enter_personal_information')}</h5>

              <hr></hr>

              <div className="row">
                {/* division */}
                <label className="col-md-12" align="left">
                  {t ('pick_your_category')} {t ('required')}
                </label>
                <div className="input-group col-lg-12 mb-4">
                  <div className="col-md-12 text-center">
                    <Dropdown
                      options={divisions}
                      onChange={this.onSelect}
                      value={this.state.division}
                      placeholder="No Category"
                    />
                  </div>
                </div>
                <div className="form-group col-lg-4 mx-auto mb-0 text-center">
                  <button
                    className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px"
                    onClick={this.requestRegistration}
                  >
                    { t ('register_to_competition')}
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

}

const mapStateToProps = state => ({
});

CompetitionRegistration.propTypes = {
  alert_set: PropTypes.func.isRequired,
  competition: PropTypes.object.isRequired,
};

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    {
      alert_set,
    }
  )
) (withRouter (CompetitionRegistration));
