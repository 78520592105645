import axios from 'axios';

import {
  TEAM_REGISTRATION_LOADING, GET_TEAM_REGISTRATION
} from './types';

// import { errors_clear } from './errorsActions';
// import { success_clear } from './successActions';

export const team_registration_check = (competition) => dispatch => {

  dispatch (team_registrations_set_loading ());

  axios.get (
    "/api/registrations/teams/check",
    { params: { competition: competition } }
  )
    .then (res =>
      dispatch ({
        type: GET_TEAM_REGISTRATION,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_TEAM_REGISTRATION,
        payload: null
      }));

}

export const team_registrations_set_loading = () => {
  return {
    type: TEAM_REGISTRATION_LOADING
  };
};
