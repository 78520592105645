import axios from "axios";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

import { SET_CURRENT_USER, ERRORS_GET, SUCCESS_GET, SET_USER_ADMIN } from "./types";

import { errors_clear } from "./errorsActions";
import { success_clear } from "./successActions";
import { alert_set } from "./alertsActions";
import { profile_clear_current } from "./profilesActions";

const cookies = new Cookies ();

// set auth token for each request
const auth_token_set = token => {

  // apply to every request
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  }

  else {
    delete axios.defaults.headers.common["Authorization"];
  }

};

export const user_session_token = (session_token) => dispatch => {

  if (session_token !== null) {
    let actual_token = `Bearer ${session_token}`;

    process.env.NODE_ENV === "production" ?
      cookies.set (process.env.REACT_APP_JWT_KEY, actual_token, {
        path: "/",
        domain: ".timecapp.app",
        secure: true
      })
    : cookies.set (process.env.REACT_APP_JWT_KEY, actual_token, {
      path: "/",
      domain: ".localhost.com",
    })

    auth_token_set (actual_token);
    dispatch (user_set_current (jwt_decode (actual_token)));
  }

}

// TODO: handle exp
// check for user token
export const user_token_check = (store) => {

  let jwt = cookies.get (
    process.env.REACT_APP_JWT_KEY
  );

  if (jwt) {
    let decoded = jwt_decode (jwt);
    auth_token_set (jwt);
    store.dispatch (user_set_current (decoded));
    store.dispatch (user_check_admin (decoded));
  }

}

export const user_check_admin = (user) => dispatch => {
  if (user.role === "god" || user.role === "admin") {
    dispatch ({
      type: SET_USER_ADMIN,
      payload: true
    })
  } else {
    axios.get ("/api/staff/check").then ((_) => {
      dispatch ({
        type: SET_USER_ADMIN,
        payload: true
      })
    }).catch ((err) => {
      console.error (err);
      dispatch ({
        type: SET_USER_ADMIN,
        payload: false
      })
    });
  }
}

// set logged user
export const user_set_current = (decoded) => {

  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }

}

// log user out
export const user_logout = () => dispatch => {

  process.env.NODE_ENV === "production" ?
    cookies.remove (process.env.REACT_APP_JWT_KEY, {
      path: "/",
      domain: ".timecapp.app"
  })
  : cookies.remove (process.env.REACT_APP_JWT_KEY, {
      path: "/",
      domain: ".localhost.com"
  });

  // remove auth header for future requests
  auth_token_set (null);
  dispatch (user_set_current ({}));
  dispatch (alert_set ("You have logged out!", "success"));

};

export const password_change = (data) => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  axios.put ("/api/users/password", data)
    .then (res => {
      dispatch (alert_set ("Password has been changed!", "success"));
      dispatch ({
        type: SUCCESS_GET,
        payload: res.data
      });
    })
    .catch (err =>
      dispatch ({
        type: ERRORS_GET,
        payload: err.response.data
      }));

}

// deletes the user
export const user_delete = () => dispatch => {

  // TODO:

}
