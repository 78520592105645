import {
  GET_CHECKOUT,
  CHECKOUT_LOADING,
} from '../actions/types';

const initial_state = {
  checkout: null,
  checkouts_loading: false
};

export default function (state = initial_state, action) {

  switch (action.type) {
    case CHECKOUT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CHECKOUT:
      return {
        ...state,
        checkout: action.payload,
        loading: false
      };
    default: return state;
  }

}
