import React, { Component } from 'react';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { CompetitionDescription } from './CompetitionDescription';

import { withTranslation } from 'react-i18next';
import {
    competition_status_string, competition_type_string
} from '../../actions/competitionsActions';

class CompetitionItem extends Component {

  render () {
    let { competition } = this.props;

    let outside_link = `/competitions/${competition._id["$oid"]}/info`;

    let competition_type = competition_type_string (competition.competition_type);
    let competition_status = competition_status_string (competition.status);

    const { t } = this.props;

    return (
    <div>
      {/* <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 mb-5"> */}
      <div className="mb-5">
        <div className="card competition-card bg-gray">
          <div className="row no-gutters">
            <div className="col-lg-4 card-img-holder">
              <img src={`api/uploads/competitions/${competition._id["$oid"]}/${competition.logo}`} className="card-img" alt="image" />
            </div>
            <div className="col-lg-8" align="left">
              <div className="card-body">
                <h2 className="main-purple">{ competition.name }</h2>
                <div className="row">
                  <div className="col-sm-6">
                    <p className="font-20px"><strong>{t ('type')}: </strong>{ competition_type }</p>
                  </div>
                  <div className="col-sm-6">
                    <p className="font-20px"><strong>Status: </strong>{ competition_status }</p>
                  </div>
                  <div className="col-sm">
                  </div>
                </div>
                <hr></hr>
                <h3>{t ('venue_name')}: { competition.venue_name }</h3>
                <h3>{t ('address')}: { competition.address.text }</h3>

                <h5>{t ('start_date')}: <Moment utc={true} format={t ('day_month_year')}>{ competition.start_date["$date"] }</Moment> </h5>
                <h5>{t ('end_date')}: <Moment utc={true} format={t ('day_month_year')}>{ competition.end_date["$date"] }</Moment> </h5>

                <CompetitionDescription description={competition.description} />

                <div align="right">
                  <a className="btn main-purple-background text-white" href={outside_link}>
                    {t ('read_more')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

}

CompetitionItem.propTypes = {
  competition: PropTypes.object.isRequired
};

export default withTranslation ()(withRouter (CompetitionItem));
