import React from 'react';

import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

export const CompetitionDescription = (description) => {
  return (
    <div>
      <ReactQuill
        // className='text-center'
        theme='bubble'
        readOnly={true}
        value={JSON.parse (description.description)}
      />
    </div>
  );
}
