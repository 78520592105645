import classnames from 'classnames';
import React, { Component } from 'react';

// redux
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// my components
import Loading from '../common/Loading';

import Footer from '../Footer';
import Navbar from '../Navbar';

// import TextField from '../input/TextField';

import DashboardNav from './DashboardNav';

// my actions
import { account_get } from '../../actions/accountActions';
import { profile_update } from '../../actions/profilesActions';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import isEmpty from '../../utils/isEmpty';

class Dashboard extends Component {

  constructor () {
    super ();
    this.state = {
      name: '',

      errors: {}
    }

    this.onChange = this.onChange.bind (this);
    this.onSubmit = this.onSubmit.bind (this);
  }

  componentDidMount () { this.props.account_get (null); }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState ({ errors: nextProps.errors });
    }

    if (!isEmpty (nextProps.success)) {
      // window.location.reload (false);
      this.props.account_get ();
    }
  }

  onChange (e) { this.setState ({ [e.target.name]: e.target.value }); }

  onSubmit (e) {
    e.preventDefault ();

    const { account } = this.props.account;

    let data = {
      name: isEmpty (this.state.name) ? account.name : this.state.name
    }

    this.props.profile_update (data);
  }

  render () {
    let { errors } = this.state;

    const { account, loading } = this.props.account;

    const { t } =this.props;

    let dashboard_content;
    if (account === null || loading) {
      dashboard_content = (
        <div className="container-fluid" align="center">
          <div className="projects-list px-3 py-5 p-md-5">
            <div id="down" className="projects-wrapper">
              <br></br>
              <Loading color="#80110f" height={'20%'} width={'20%'} />
            </div>
          </div>
        </div>
      );
    }

    else {
      dashboard_content = (
      <div className="container">
        <div className="py-5 mt-4 align-items-center">
          <form noValidate onSubmit={ this.onSubmit }>
            <div className="row">

              {/* name */}
              <label className="col-md-12">{t ('your_name')}</label>
              <div className="input-group col-lg-12 mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white px-4 border-md border-right-0">
                    <i className="fa fa-user main-dark-blue"></i>
                  </span>
                </div>

                <input
                  className={ classnames('form-control form-control-lg', {
                    'is-invalid': errors.name
                  })}
                  type="text"
                  placeholder={ account.name }
                  name="name"
                  value={ this.state.name }
                  onChange={ this.onChange }
                />
                { errors.name && <div className="invalid-feedback">{ errors.name }</div> }

              </div>

              {/* bio */}
              {/* <label className="col-md-12">A short description of yourself</label>
              <div className="input-group col-lg-12 mb-4">
                <TextArea
                  cols="100" rows="3"
                  placeholder={ isEmpty (account.bio) ? "Bio (128 max characters)" : account.bio }
                  name="bio"
                  value={ this.state.bio }
                  onChange={ this.onChange }
                  error={ errors.bio }
                />
              </div> */}

              <div className="form-group col-lg-4 mx-auto mb-0">
                <button type="submit" className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px">
                  {t ('update_your_profile')}
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
      );
    }

    return (
      <div>
        <Navbar />

        <DashboardNav />

        <div className="main-wrapper">
          <section className="gray-bg docs-header">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-8 text-center">
                  <div className="p-60px-b">
                    <h1 className="main-purple font-w-700 font-60px md-font-40px sm-font-30px">Your Profile</h1>
                    <br></br>
                    <h3>{t ('public_values_are_visible')}</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>

          { dashboard_content }

          <Footer />
        </div>
      </div>
    );
  }

}

Dashboard.propTypes = {
  account_get: PropTypes.func.isRequired,
  profile_update: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success,
  account: state.account
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps, { account_get, profile_update }
  )
)(Dashboard);
