import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

// redux
import PropTypes from 'prop-types';

// my components
import Footer from '../Footer';
import Navbar from '../Navbar';

// my actions
import { checkout_cancel } from '../../actions/checkoutActions';
import { competition_get_info } from '../../actions/competitionsActions';

class CheckoutCancel extends Component {

  constructor () {
    super ();

    this.state = {
      mobile: false
    }
  }

  componentDidMount () {
    let competition = this.props.match.params.id;

    let params = new URLSearchParams (this.props.location.search);

    let user = params.get ("user");
    let phase = params.get ('phase');
    let division = params.get ('division');
    let teams = params.get ('teams');
    let mobile = params.get ("mobile") == "True";

    this.setState({
      mobile: mobile
    });

    if (competition) {
      // fetch general competition's information
      this.props.competition_get_info (competition, null);

      // request cancelful checkout
      this.props.checkout_cancel (user, competition, phase, division, teams);
    }
  }

  render () {
    let competition = this.props.match.params.id;
    const {t} = this.props;

    return (
    <div>
      <Navbar />

      <div className="register-area d-flex">
        <div className="register-side-content bg-img error-bg"></div>

        <div className="register-content-wrapper d-flex align-items-center">
          <div className="register-content">
            <h1 className="main-purple">{t ('failed_checkout')}</h1>
            <hr></hr>
            {
              this.state.mobile 
              ? (
                <a href="timecapp://cancel" target="_blank" className="main-purple">
                  {t ('exited_payment_page')}
                </a>
              )
              : (
                <p>{t ('you_have_exited_disc_1')}<Link to={`/competitions/${competition}/info`} target="_top" className="main-purple">{t ('competitionss')}</Link>{t('you_have_exited_disc_2')}</p>
              )
            }
            
            <br></br>

            <br></br>
            <br></br>

            <hr></hr>

            <br></br>

            <h4>{t('contact_us_at')}</h4>
            <h3 className="main-purple">timecappstadium@gmail.com</h3>
          </div>
        </div>
      </div>

      <Footer />
    </div>
    );
  }
}

CheckoutCancel.propTypes = {
  errors: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
  checkout_cancel: PropTypes.func.isRequired,
  competition_get_info: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    { checkout_cancel, competition_get_info }
  ) (CheckoutCancel)
);
