const getAgeFromDate = (date) => {
    let birthDate = new Date (date);

    let age = Date.now () - birthDate; 

    age = new Date (age);

    age = Math.abs (age.getFullYear () - 1970);

    return age;
}

export default getAgeFromDate;