import { ALERT_SET, ALERT_REMOVE } from '../actions/types';

const initial_state = [];

export default function (state = initial_state, action) {

  const { type, payload } = action;

  switch (type) {
    case ALERT_SET:
      return [...state, payload];
    case ALERT_REMOVE:
      return state.filter (alert => alert.id !== payload);
    default:
      return state;
  }

}
