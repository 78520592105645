import axios from 'axios';

import {
  ERRORS_GET, SUCCESS_GET,
  GET_ACCOUNT,
  ACCOUNT_LOADING,
  CLEAR_CURRENT_ACCOUNT,
} from './types';

import { errors_clear } from './errorsActions';
import { success_clear } from './successActions';
import { alert_set } from './alertsActions';

// get the authenticated user's account
export const account_get = (session_token) => dispatch => {

  const config = {};

  if (session_token !== null) {
    config["headers"] = { Authorization: `Bearer ${session_token}` }
  }

  dispatch (errors_clear ());
  dispatch (success_clear ());

  dispatch (account_set_loading ());
  axios.get ('/api/users/account', config)
  .then (res =>
    dispatch ({
      type: GET_ACCOUNT,
      payload: res.data
    }))
  .catch (err =>
    dispatch ({
      type: GET_ACCOUNT,
      payload: null
    }));

}

export const email_change = (data) => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  axios.put ('/api/users/email', data)
    .then (res => {
      dispatch (alert_set ('Email has been changed!', 'success'));
      dispatch ({
        type: SUCCESS_GET,
        payload: res.data
      });
    })
    .catch (err =>
      dispatch ({
        type: ERRORS_GET,
        payload: err.response.data
      }));

}

// account loading
export const account_set_loading = () => {
  return {
    type: ACCOUNT_LOADING
  };
};

// clear account
export const account_clear_current = () => {
  return {
    type: CLEAR_CURRENT_ACCOUNT
  };
};
