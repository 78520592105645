import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';


class Landing extends Component {

  render () {

	return (
      <div>
        timeCapp Dashboard!
      </div>
    );
  }
  
}

Landing.propTypes = {};

const mapStateToProps = state => ({});

export default compose(
  withRouter,
  connect (mapStateToProps, { }) 
)(Landing);
