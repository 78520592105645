import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Moment from 'react-moment';

import { withTranslation } from 'react-i18next';
import {
    phase_status_string, phase_type_string
} from '../../../actions/phasesActions';

class RegistrationPhase extends Component {

  render () {
    let { phase } = this.props;

    let phase_type = phase_type_string (phase.phase_type);
    let phase_status = phase_status_string (phase.status);

    const { t } =this.props;

    return (
    <div className="mb-5">
      <div className="card competition-card bg-gray">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="card-body">
              <h3>{t ('from')} <Moment utc={true} format={t ('day_month_year')}>{ phase.start_date["$date"] }</Moment> {t ('to')} <Moment utc={true} format={t ('day_month_year')}>{ phase.end_date["$date"] }</Moment> </h3>
              
              <hr></hr>

              <div className="row">
                <div className="col-sm-6">
                  <p className="font-20px"><strong>{t ('type')}: </strong>{ phase_type }</p>
                </div>
                <div className="col-sm-6">
                  <p className="font-20px"><strong>Status: </strong>{ phase_status }</p>
                </div>
                <div className="col-sm">
                </div>
              </div>

              <h5 className="font-18px">{ phase.description }</h5>

              <br></br>

              <h4>{t ('available')}: { phase.available - phase.sold } / { phase.available }</h4>
              <br></br>

              {
                phase.requires_payment === true ?
                <h4> {t ('price')}: { phase.price } { phase.currency.code }</h4>
                : phase.payment_confirmation === true ? <h4>{t ('requires_external_payment')}</h4>
                : <h4>{t ('price')}: {t ('price')}</h4>
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

}

RegistrationPhase.propTypes = {
  phase: PropTypes.object.isRequired
};

export default withTranslation ()(RegistrationPhase);
