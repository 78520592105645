import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Moment from 'react-moment';

import { withTranslation } from 'react-i18next';
import {
    registration_status_string
} from '../../../actions/registrationsActions';

class MemberRegistration extends Component {

  render () {
    let { member_registration } = this.props;

    const { t } =this.props;

    let member_registration_status = registration_status_string (member_registration.team.status);

    return (
    <div className="mb-5">
      <div className="card competition-card bg-gray">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="card-body">
              <h5>{t ('you_have_already_registered_as_a_member')}</h5>

              <h3>Status: { member_registration_status }</h3>

              <h5>{t ('date')}: <Moment utc={true} format={t ('day_month_year')}>{ member_registration.date["$date"] }</Moment> </h5>
      
              <hr></hr>

              <div className="row">
                <div className="col-sm-6">
                  <p className="font-20px"><strong>{t ('team_name_data')}: </strong>{ member_registration.team.name }</p>
                </div>
                <div className="col-sm-6">
                  <p className="font-20px"><strong>{t ('member_name_data')}: </strong>{ member_registration.name }</p>
                </div>
                <div className="col-sm">
                </div>
              </div>

              <p className="font-24px">{t ('you_are_registered_to')}: <strong>{ member_registration.division.name }</strong></p>

              <p className="font-24px">{t ('any_further_updates_will_be_sent_to')}<strong>{ member_registration.email }</strong></p>

              <p>{t ('consult_timecapp_app')}</p>
              <p>{t ('contact_us_at')}<strong>timecappstadium@gmail.com</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

}

MemberRegistration.propTypes = {
  member_registration: PropTypes.object.isRequired
};

export default withTranslation ()(MemberRegistration);
