import axios from 'axios';

import {
  ERRORS_GET, GET_CHECKOUT, SUCCESS_GET
} from './types';

import { alert_set } from './alertsActions';
import { errors_clear } from './errorsActions';
import { success_clear } from './successActions';

// POST /api/checkout/session
export const checkout_session = (form_data, session_token, t) => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  let headers = {
    "Content-Type": "multipart/form-data"
  }

  if (session_token !== null) {
    headers["Authorization"]= `Bearer ${session_token}`;
  }

  // multi-part request
  axios ({
    method: "post",
    url: "/api/checkout/session",
    headers: headers,
    data: form_data,
  })
  .then (res => {
    // console.log (res.data)
    dispatch ({
      type: GET_CHECKOUT,
      payload: res.data
    })})
  .catch (err => {
    if (err.response.status === 400) {
      let errors = err.response.data;

      if (errors.user_image) {
        dispatch (alert_set ( t ('valid_image_error'), "danger"));
      }

      else if (errors.box_image) {
        dispatch (alert_set ( t ('valid_image_error'), "danger"));
      }

      else if (errors.gender) {
        dispatch (alert_set ( t ('valid_gender_error'), "danger"));
      }

      else if (errors.country) {
        dispatch (alert_set ( t ('valid_country_error'), "danger"));
      }

      else if (errors.n_documents) {
        dispatch (alert_set ( t ('valid_age_proof_error'), "danger"));
      }

      else if (errors.payment) {
        dispatch (alert_set ( t ('valid_receipt_error'), "danger"));
      }

      else if (errors.division) {
        dispatch (alert_set ( t ('valid_category_error'), "danger"));
      }

      else if (errors.phase) {
        dispatch (alert_set ( t ('valid_register_error'), "danger"));
      }

      else if (errors.user) {
        dispatch (alert_set (errors.user, "danger"));
      }

      else if (errors.qualifier) {
        dispatch (alert_set (errors.qualifier, "danger"));
      }
      
      else if (errors.registration) {
        dispatch (alert_set ( t ('already_registered_user_error'), "danger"));
      }
      
      else {
        dispatch (alert_set ( t ('missing_information'), "danger"));
      }

      dispatch ({
        type: ERRORS_GET,
        payload: errors
      });
    }

    else if (err.response.status === 401) {
      dispatch (alert_set ("Failed to authenticate the current user.", "danger"));
    }

    else if (err.response.status === 413) {
      dispatch (alert_set ("Your files are too large! Please try sending smaller documents.", "danger"));
    }

    else {
      dispatch (alert_set ("An error has ocurred! Please try again later.", "danger"));
    }
  });

}

// POST /api/checkout/session/team
export const checkout_team_session = (form_data, session_token, t) => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  let headers = {
    "Content-Type": "multipart/form-data"
  }

  if (session_token !== null) {
    headers["Authorization"]= `Bearer ${session_token}`;
  }

  axios ({
    method: "post",
    url: "/api/checkout/session/team",
    headers: headers,
    data: form_data,
  })
  .then (res => {
    // console.log (res.data)
    dispatch ({
      type: GET_CHECKOUT,
      payload: res.data
    })})
  .catch (err => {
    if (err.response.status === 400) {
      let errors = err.response.data;

      if (errors.team) {
        dispatch (alert_set (errors.team, "danger"));
      }

      else if (errors.team_image) {
        dispatch (alert_set (t ('valid_image_error'), "danger"));
      }

      else if (errors.box_image) {
        dispatch (alert_set (t ('valid_image_error'), "danger"));
      }

      else if (errors.country) {
        dispatch (alert_set (t ('valid_country_error'), "danger"));
      }

      else if (errors.payment) {
        dispatch (alert_set (t ('valid_receipt_error'), "danger"));
      }

      else if (errors.phase) {
        dispatch (alert_set (t ('valid_register_error'), "danger"));
      }

      else if (errors.user) {
        dispatch (alert_set (errors.user, "danger"));
      }

      else if (errors.qualifier) {
        dispatch (alert_set (errors.qualifier, "danger"));
      }

      else if (errors.members) {
        dispatch (alert_set (errors.members, "danger"));
      }
      
      else {
        dispatch (alert_set (t ('missing_information'), "danger"));
      }

      dispatch ({
        type: ERRORS_GET,
        payload: errors
      });
    }

    else if (err.response.status === 401) {
      dispatch (alert_set ("Failed to authenticate the current user.", "danger"));
    }

    else if (err.response.status === 413) {
      dispatch (alert_set ("Your files are too large! Please try sending smaller documents.", "danger"));
    }

    else {
      dispatch (alert_set ("An error has ocurred! Please try again later.", "danger"));
    }
  });

}

export const checkout_success = (
  user, competition, phase, division, teams, created
) => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  axios.get (
    "/api/checkout/success",
    {
      params: {
        user: user,
        competition: competition,
        phase: phase,
        division: division,
        teams: teams,
        created: created
      } 
    }
  )
    .then (res =>
      dispatch ({
        type: SUCCESS_GET,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: ERRORS_GET,
        payload: err.response.data
      }));

}

export const checkout_cancel = (
  user, competition, phase, division, teams
) => dispatch => {

  dispatch (errors_clear ());
  dispatch (success_clear ());

  axios.get (
    "/api/checkout/cancel",
    {
      params: {
        user: user,
        competition: competition,
        phase: phase,
        division: division,
        teams: teams
      } 
    }
  )
    .then (res =>
      dispatch ({
        type: SUCCESS_GET,
        payload: res.data
      }))
    .catch (err => 
      dispatch ({
        type: ERRORS_GET,
        payload: err.response.data
      }));

}
