import { validate } from 'email-validator';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import getAgeFromDate from '../../../utils/dateToAge';

import { connect } from 'react-redux';

import Dropdown from 'react-dropdown';

import CountrySelector from '../../input/CountrySelect';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { member_search_clean_data, member_search_email } from '../../../actions/membersActions';

class MemberRegistrationInput extends Component {
  constructor () {
    super ();
    this.state = {
      email: "",
      search_email: false,
      bad_email: false,
      email_in_team: false,
      loading: false,
      not_found: false
    };

    this.onEmailChange = this.onEmailChange.bind (this);
    this.onEmailSearch = this.onEmailSearch.bind (this);
  }

  componentDidMount () {
    this.state.search_email = this.props.its_me === this.props.idx -1;
  }

  componentWillReceiveProps (next_props) {
    // If its me checkbox is checked
    if (next_props.its_me === next_props.idx - 1) {
      // If just changed to true
      if (this.props.its_me !== next_props.idx -1) {
        this.setState({
          email: next_props.email,
          search_email: true
        })
      } else {
        this.setState ({
          search_email: next_props.email === this.state.email
        });
      }
    }

    // If member email data has information
    if (next_props.member.member_email_data [this.props.idx-1] !== undefined) {
      const member_email_data = next_props.member.member_email_data [this.props.idx-1];

      this.setState ({
        loading: member_email_data.loading,
        not_found: member_email_data.not_found
      });

      // If email data already has a response
      if (
        member_email_data.data !== null || member_email_data.not_found === true
      ) {
        let email_check = this.props.email;

        if (member_email_data.data !== null) {
          email_check = member_email_data.data["email"];
        }
        this.setState ({
          search_email: email_check === this.props.email
        });

        if (member_email_data.data !== null  && !this.state.search_email) {
          // If data exists and has props have not been updated
          const genders = [
            { value: 0, label: "None" },
            { value: 1, label: "Male" },
            { value: 2, label: "Female" },
            { value: 3, label: "Other" },
          ];
          
          let age = getAgeFromDate(member_email_data.data.birth_date["$date"]);
          this.props.updateMemberData ({
            "email": member_email_data.data["email"],
            "first_name": member_email_data.data["name"],
            "last_name": member_email_data.data["surname"],
            "name": member_email_data.data["username"],
            "gender": genders [member_email_data.data["gender"]],
            "age": age
          })
        }
      }
    }
  }

  onEmailSearch () {
    let bad_email = !validate (this.props.email);
    let email_in_team = this.props.email_in_team (this.props.email);
    if (!bad_email && !email_in_team) {
      this.props.member_search_email (this.props.email, this.props.idx -1);
    }
    this.setState ({
      "bad_email": bad_email,
      "email_in_team": email_in_team
    });
  }

  onEmailChange (e) {
    if (this.state.search_email) {
      this.setState ({
        search_email: false
      });
      this.props.member_search_clean_data (this.props.idx - 1);
      this.props.updateMemberData (null);
    }
    this.props.onChange (e);
    this.setState({"email": e.target.value.toLowerCase ()});
  }

  render () {
    let {
      idx, phase, first_name, last_name, name, email, age, gender, country, tshirt_size_value, 
      pants_size_value, bath_suit_type_value, bath_suit_size_value,
      its_me,
      onChange, onSelectGender, onSelectCountry, onSelectSize, onItsMeCheck
    } = this.props;

    const { t } =this.props;

    const genders = [
      // { value: 0, label: "None" },
      { value: 1, label: "Male" },
      { value: 2, label: "Female" },
      { value: 3, label: "Other" }
    ];

    const tshirt_sizes = [
      // { value: 0, label: "None" },
      // { value: 1, label: "XXS" },
      { value: 2, label: "XS" },
      { value: 3, label: "S" },
      { value: 4, label: "M" },
      { value: 5, label: "L" },
      { value: 6, label: "XL" },
      { value: 7, label: "XXL" },
      // { value: 8, label: "XXXL" }
    ];

    const pants_sizes = [
      // { value: 0, label: "None" },
      { value: 1, label: "XS" },
      { value: 2, label: "S" },
      { value: 3, label: "M" },
      { value: 4, label: "L" },
      // { value: 5, label: "XL" },
      // { value: 6, label: "XXL" },
      // { value: 7, label: "XXXL" },
    ];

    const bath_suit_types = [
      // { value: 0, label: "None" },
      { value: 1, label: "1 pieza" },
      { value: 2, label: "2 piezas" },
      { value: 3, label: "NA" },
    ];

    const bath_suit_sizes = [
      // { value: 0, label: "None" },
      { value: 1, label: "XS" },
      { value: 2, label: "S" },
      { value: 3, label: "M" },
      { value: 4, label: "L" },
      { value: 5, label: "XL" },
      { value: 6, label: "NA" },
    ];

    let tshirt_size = (
      <Fragment>
        <label className="col-md-12" align="left">
          {t ('enter_tshirt')} {t ('required')}
        </label>
        <div className="text-center input-group col-lg-12 mb-4">
          <div className="col-md-12 text-center">
            <Dropdown
              options={tshirt_sizes}
              disabled={!this.state.search_email}
              onChange={(option) => onSelectSize("tshirt_size", option)}
              value={tshirt_size_value.label}
              placeholder="No t-shirt size"
            />

          </div>
        </div>
      </Fragment>
    );

    let pants_size = (
      <Fragment>
        <label className="col-md-12" align="left">
          {t ('enter_pants_size')} {t ('required')}
        </label>
        <div className="text-center input-group col-lg-12 mb-4">
          <div className="col-md-12 text-center">
            <Dropdown
              disabled={!this.state.search_email}
              options={pants_sizes}
              onChange={ (option) => onSelectSize ("pants_size", option)}
              value={pants_size_value.label}
              placeholder="No pants size"
            />

          </div>
        </div>
      </Fragment>
    );

    let bath_suit_type = (
      <Fragment>
        <label className="col-md-12" align="left">
        {t ('enter_bath_suit_type')} {t ('required')}
        </label>
        <div className="text-center input-group col-lg-12 mb-4">
          <div className="col-md-12 text-center">
            <Dropdown
              disabled={!this.state.search_email}
              options={bath_suit_types}
              onChange={ (option) => onSelectSize ("bath_suit_type", option)}
              value={bath_suit_type_value.label}
              placeholder="No bathing suit type"
            />

          </div>
        </div>
      </Fragment>
    );

    let bath_suit_size = (
      <Fragment>
        <label className="col-md-12" align="left">
        {t ('enter_bath_suit_size')} {t ('required')}
        </label>
        <div className="text-center input-group col-lg-12 mb-4">
          <div className="col-md-12 text-center">
            <Dropdown
              disabled={!this.state.search_email}
              options={bath_suit_sizes}
              onChange={ (option) => onSelectSize ("bath_suit_size", option)}
              value={bath_suit_size_value.label}
              placeholder="No bathing suit size"
            />

          </div>
        </div>
      </Fragment>
    );

    let loading_content = (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    );

    let not_found_content = (
      <p style={{color: "red"}}>{t ('no_registered_email')}</p>
    );

    let bad_email_content = (
      <p style={{color: "red"}}>{t ('no_valid_email')}</p>
    );

    let email_in_team_content = (
      <p style={{color: "red"}}>{t ('already_registered_email')}</p>
    );
    
    return (
    <div className="card-body">
      <h5 align="left">{t ('member')} {idx}</h5>
      {
        this.props.coach 
        ? null
        : (
          <div className='form-check'>
            <input 
              className="form-check-input" 
              id={`team-member-check-${idx-1}`} 
              type="checkbox"  
              checked={its_me === idx-1}
              onChange={(e) => onItsMeCheck (idx - 1)}
            />
            <label className="form-check-label" htmlFor={`team-member-check-${idx-1}`}>
              <h5>{t ('its_me')}</h5>
            </label>
          </div>
        )
      }
      
      <hr></hr>

      <div className="row">
        {/* email */}
        <label className="col-md-12" align="left">
          {t ('enter_email')} {t ('required')}
        </label>
        <div className='row w-100 mb-4'>
          <div className="input-group col-lg-10">
            <div className="input-group-prepend">
              <span className="input-group-text bg-white px-4 border-md border-right-0">
                <i className="fas fa-at main-dark-blue"></i>
              </span>
            </div>

            <input
              className='form-control form-control-lg'
              type="email"
              placeholder="e.g. juan@timecapp.app"
              name="email"
              value={ email }
              onChange={ this.onEmailChange } 
                
            />
          </div>
          <button className='col-lg-2 btn btn-sm btn-primary' onClick={() => this.onEmailSearch ()}>
            {
              this.state.loading
              ? loading_content
              : t ('search')
            }
          </button>
        </div>

        {
          this.state.not_found
          ? not_found_content
          : this.state.bad_email
            ? bad_email_content
            : this.state.email_in_team
              ? email_in_team_content
              : null
        }

        {/* first name */}
        <label className="col-md-12" align="left">
          {t ('enter_name')} {t ('required')}
        </label>
        <div className="input-group col-lg-12 mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white px-4 border-md border-right-0">
              <i className="fas fa-user main-dark-blue"></i>
            </span>
          </div>

          <input
            className='form-control form-control-lg'
            disabled={!this.state.search_email}
            type="text"
            placeholder="e.g. Juan Carlos"
            name="first_name"
            value={ first_name }
            onChange={ onChange }
          />
        </div>

        {/* last name */}
        <label className="col-md-12" align="left">
        {t ('enter_lastname')} {t ('required')}
        </label>
        <div className="input-group col-lg-12 mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white px-4 border-md border-right-0">
              <i className="fas fa-user main-dark-blue"></i>
            </span>
          </div>

          <input
            className='form-control form-control-lg'
            disabled={!this.state.search_email}
            type="text"
            placeholder="e.g. Lara Anaya"
            name="last_name"
            value={ last_name }
            onChange={ onChange }
          />
        </div>

        {/* name */}
        <label className="col-md-12" align="left">
          {t ("enter_username")} {t ('required')}
        </label>
        <div className="input-group col-lg-12 mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white px-4 border-md border-right-0">
              <i className="fas fa-user main-dark-blue"></i>
            </span>
          </div>

          <input
            className='form-control form-control-lg'
            disabled={!this.state.search_email}
            type="name"
            placeholder="e.g. Juan Lara"
            name="name"
            value={ name }
            onChange={ onChange }
          />
        </div>

        {/* age */}
        <label className="col-md-12" align="left">
        {t ("enter_age")} {t ('required')}
        </label>
          <div className="input-group col-lg-12 mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white px-4 border-md border-right-0">
              <i className="fas fa-calendar main-dark-blue"></i>
            </span>
          </div>

          <input
            className='form-control form-control-lg'
            disabled={!this.state.search_email}
            type="number"
            placeholder="e.g. 18"
            name="age"
            value={ age }
            onChange={ onChange }
          />
        </div>

        {/* gender */}
        <label className="col-md-12" align="left">
        {t ("enter_gender")} {t ('required')}
        </label>
        <div className="text-center input-group col-lg-12 mb-4">
          <div className="col-md-12 text-center">
            <Dropdown
              options={genders}
              disabled={!this.state.search_email}
              onChange={onSelectGender}
              value={gender}
              placeholder="No Gender"
            />
          </div>
        </div>

        {/* country */}
        <label className="col-md-12" align="left">
        {t ("enter_country")} {t ('required')}
        </label>
        <div className="input-group col-lg-12 mb-4">
          <div className="col-md-12 text-center">
            <CountrySelector
              placeholder="Select your country"
              disabled={!this.state.search_email}
              name="country"
              value={country}
              onChange={onSelectCountry}
            />
          </div>
        </div>

        { phase.tshirt_size === true && tshirt_size }

         {/* pants_size */}
         { phase.pants_size === true && pants_size }

          {/* bath_suit_type */}
          { phase.bath_suit_type === true && bath_suit_type }

          {/* bath_suit_size */}
          { phase.bath_suit_size === true && bath_suit_size }


      </div>
    </div>
    );
  }

}

MemberRegistrationInput.propTypes = {
  phase: PropTypes.object.isRequired,
  member_search_email: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  member: state.member
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    {
      member_search_email,
      member_search_clean_data
    }
  )
)
(MemberRegistrationInput);
