import {
  GET_REGISTRATION,
  REGISTRATION_LOADING,
  REGISTRATION_VALIDATING,
  GET_REGISTRATION_VALIDATION
} from '../actions/types';

const initial_state = {
  registration: null,
  registrations_loading: false,
  validation: {
    loading: false,
    validated: false,
    error: null
  }
};

export default function (state = initial_state, action) {

  switch (action.type) {
    case REGISTRATION_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_REGISTRATION:
      return {
        ...state,
        registration: action.payload,
        loading: false
      };
    case REGISTRATION_VALIDATING:
      return {
        ...state,
        validation: {
          loading: true,
          validated: false,
          error: null
        }
      }
    case GET_REGISTRATION_VALIDATION:
      return {
        ...state,
        validation: {
          loading: false,
          validated: action.payload.value,
          error: action.payload.error
        }
      }
    default: return state;
  }

}
