import classnames from 'classnames';
import { validate } from 'email-validator';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import getAgeFromDate from '../../../utils/dateToAge';

import PropTypes from 'prop-types';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import Footer from '../../Footer';
import Navbar from '../../Navbar';

import Loading from '../../common/Loading';

import DashboardNav from '../DashboardNav';
import CompetitionHeader from './CompetitionHeader';
import Division from './Division';
import RegistrationPhase from './RegistrationPhase';

import CountrySelector from '../../input/CountrySelect';
import ImageInput from '../../input/ImageInput';

import { account_get } from '../../../actions/accountActions';
import { user_session_token } from '../../../actions/authActions';
import { checkout_session } from '../../../actions/checkoutActions';

import { athlete_clean_search_data, athlete_search_email } from '../../../actions/athletesActions';
import { competition_get_info } from '../../../actions/competitionsActions';
import { division_get_info } from '../../../actions/divisionsActions';
import { phase_get_current } from '../../../actions/phasesActions';

class AthleteRegistrationInput extends Component {
  full_genders = [
    { value: 0, label: "None" },
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" },
  ];

  constructor () {
    super ();

    this.state = {
      session_token: null,
      mobile: null,

      competition_id: '',
      phase_id: '',
      division: '',
  
      name: '',
      first_name: '',
      last_name: '',
      email: '',
      user_image: null,
  
      box: '',
      box_image: null,
  
      age: '',
      gender: '',
      country: '',
      tshirt_size: '',
      pants_size: '',
      bath_suit_type: '',
      bath_suit_size: '',
      document: null,
      payment: null,

      its_me: false,

      waiver_confirmation: false,

      searching: false,
      search_email: false,
      bad_email: false,
      not_found: false,

      errors: {}
    }
  
    this.onChange = this.onChange.bind (this);
    this.onImageUpload = this.onImageUpload.bind (this);
    this.onBoxImageUpload = this.onBoxImageUpload.bind (this);
    this.onDocumentUpload = this.onDocumentUpload.bind (this);
    this.onPaymentUpload = this.onPaymentUpload.bind (this);
    this.onSelectGender = this.onSelectGender.bind (this);
    this.onSelectSize = this.onSelectSize.bind (this);
    this.onSelectCountry = this.onSelectCountry.bind (this);
    this.onFormSubmit = this.onFormSubmit.bind (this);
    this.onItsMeCheck = this.onItsMeCheck.bind (this);
    this.onEmailSearch = this.onEmailSearch.bind (this);
    this.onEmailChange = this.onEmailChange.bind (this);
  }

  componentDidMount () {
    let competition = this.props.match.params.id;

    let params = new URLSearchParams (this.props.location.search);
    let division = params.get ('division');
    let session_token = params.get ('token');
    let mobile = params.get ("mobile");

    this.setState ({
      division: division,
      session_token: session_token,
      mobile: mobile
    });

    // handle user session token
    this.props.user_session_token (session_token);

    // fetch the user's main information
    this.props.account_get (session_token);

    // fetch general competition's information
    this.props.competition_get_info (competition, session_token);

    // fetch the selected division's information
    this.props.division_get_info (competition, division, session_token);

    // fetch the competition's registration phases
    this.props.phase_get_current (competition, division, session_token);
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState ({ errors: nextProps.errors });
    }

    if (
      (nextProps.competition.competition === null)
      && this.props.competition.competitions_loading
      && !this.props.account.loading
    ) {
      this.props.history.push ('/404');
    }

    if (nextProps.checkout.checkout !== null) {
      // redirect to stripe's checkout
      // console.log (nextProps.checkout.checkout);
      window.location.href = nextProps.checkout.checkout.checkout_session_url;
    }

    // Set state to manage current state of searching email
    this.setState({
      searching: nextProps.athletes.loading,
      not_found: nextProps.athletes.not_found,
      search_email: nextProps.athletes.not_found || nextProps.athletes.data !== null
    })

    // If its not waiting for data
    if (!nextProps.athletes.loading) {
      // If has data set state
      if (nextProps.athletes.data !== null) {
        const {data} = nextProps.athletes;

        let age = getAgeFromDate (data.birth_date["\$date"]);

        let first_name = data.name.split (" ")[0];
        let last_name = data.surname.split (" ")[0];

        this.setState ({
          name: `${first_name} ${last_name}`,
          first_name: data.name,
          last_name: data.surname,
          email: data.email,
          box: data.box.toUpperCase (),
          age: age,
          gender: this.full_genders [data.gender],
          search_email: true
        });
      }
    }
  }

  onChange (e, uppercase = false, lowercase = false) {
    let value = e.target.value;

    if (uppercase) {
      value = value.toUpperCase ();
    } else if (lowercase) {
      value = value.toLowerCase ();
    }
    this.setState ({[e.target.name]: value});
  }
  
  onImageUpload (value) {
    this.setState ({user_image: value});
  }

  onBoxImageUpload (value) {
    this.setState ({box_image: value});
  }

  onDocumentUpload (value) {
    this.setState ({document: value});
  }

  onPaymentUpload (value) {
    this.setState ({payment: value});
  }

  onSelectGender (option) {
    // console.log ("Selected gender ", option.label);
    this.setState ({gender: option});
  }

  onSelectSize (key, option) {
    const bath_fields = ["bath_suit_type", "bath_suit_size"];
    if (bath_fields.includes (key) && option.label === "NA") {
      this.setState({
        "bath_suit_type": {"value": 3, "label": "NA"},
        "bath_suit_size": {value: 6, label: "NA"}
      })
    } else {
      // console.log ("Selected gender ", option.label);
      this.setState ({[key]: option});
    }
  }

  onSelectCountry (value) {
    // console.log ("Selected country ", value);
    this.setState ({country: value.label});
  }

  onItsMeCheck (value) {
    this.setState({its_me: value});
    
    if (value) {
      let account = this.props.account.account;
      
      let age = getAgeFromDate (account.birth_date["$date"]);

      let first_name = account.name.split (" ")[0];
      let last_name = account.surname.split (" ")[0];
      
      this.setState({
        name: `${first_name} ${last_name}`,
        first_name: account.name,
        last_name: account.surname,
        email: account.email,
        box: account.box.toUpperCase (),
        age: age,
        gender: this.full_genders [account.gender],
        search_email: true
      });
      
    } else {
      this.setState ({
        search_email: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        age: '',
        gender: '',
        box: ''
      })
    }
  }

  onEmailSearch () {
    let bad_email = !validate (this.state.email);
    if (!bad_email) {
      this.props.athlete_search_email (this.state.email);
    }
    this.setState ({
      bad_email: bad_email,
    });
  }

  onEmailChange (e) {
    if (this.state.search_email) {
      this.props.athlete_clean_search_data ();
      this.setState ({
        search_email: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        box: '',
        age: '',
        gender: '',
        its_me: false,
      });
    }
    this.onChange (e, false, true);
  }

  // request checkout
  onFormSubmit (e) {
    e.preventDefault ();

    const { phase } = this.props.phase;

    const form_data = new FormData ();
    form_data.append ("competition", this.props.competition.competition._id["$oid"]);
    form_data.append ("phase", this.props.phase.phase._id["$oid"]);
    form_data.append ("division", this.state.division);

    if (this.state.mobile !== null) {
      form_data.append ("mobile", this.state.mobile);
    }

    if (this.state.not_found) {
      form_data.append ("create", true);
    }

    form_data.append ("its_me", this.state.its_me);

    form_data.append ("name", this.state.name);
    form_data.append ("first_name", this.state.first_name);
    form_data.append ("last_name", this.state.last_name);
    form_data.append ("email", this.state.email);

    if (this.state.user_image !== null) {
      form_data.append ("user_image", this.state.user_image);
    }

    form_data.append ("box", this.state.box);

    if (this.state.box_image !== null) {
      form_data.append ("box_image", this.state.box_image);
    }

    form_data.append ("age", this.state.age);
    form_data.append ("gender", this.state.gender.value);
    form_data.append ("tshirt_size", this.state.tshirt_size.value);
    form_data.append ("pants_size", this.state.pants_size.value);

    form_data.append ("bath_suit_type", this.state.bath_suit_type.value);
    form_data.append ("bath_suit_size", this.state.bath_suit_size.value);

    form_data.append ("country", this.state.country);

    // proof of age
    if (this.state.document !== null) {
      form_data.append ("n_documents", 1);
      form_data.append ("document-0", this.state.document);
    }

    else {
      form_data.append ("n_documents", 0);
    }

    if (phase.payment_confirmation && (this.state.payment !== null)) {
      form_data.append ("payment", this.state.payment);
    }

    if (phase.requires_waiver) {
      form_data.append ("waiver_confirmation", this.state.waiver_confirmation);
    }

    this.props.checkout_session (form_data, this.state.session_token, this.props.t);
  }

  onReturnToCompetition () {
    let competition = this.props.match.params.id;
    window.location.href = `/competitions/${competition}/info`;
  }

  render () {
    let { errors } = this.state;

    let {t} =this.props;

    const genders = [
      // { value: 0, label: "None" },
      { value: 1, label: "Male" },
      { value: 2, label: "Female" },
      { value: 3, label: "Other" },
    ];

    const tshirt_sizes = [
      // { value: 0, label: "None" },
      // { value: 1, label: "XXS" },
      { value: 2, label: "XS" },
      { value: 3, label: "S" },
      { value: 4, label: "M" },
      { value: 5, label: "L" },
      { value: 6, label: "XL" },
      { value: 7, label: "XXL" },
      // { value: 8, label: "XXXL" },
    ];

    const pants_sizes = [
      // { value: 0, label: "None" },
      { value: 1, label: "XS" },
      { value: 2, label: "S" },
      { value: 3, label: "M" },
      { value: 4, label: "L" },
      // { value: 5, label: "XL" },
      // { value: 6, label: "XXL" },
      // { value: 7, label: "XXXL" },
    ];

    const bath_suit_types = [
      // { value: 0, label: "None" },
      { value: 1, label: "1 pieza" },
      { value: 2, label: "2 piezas" },
      { value: 3, label: "NA" },
    ];

    const bath_suit_sizes = [
      // { value: 0, label: "None" },
      { value: 1, label: "XS" },
      { value: 2, label: "S" },
      { value: 3, label: "M" },
      { value: 4, label: "L" },
      { value: 5, label: "XL" },
      { value: 6, label: "NA" },
    ];

    const { account, loading } = this.props.account;
    const { competition, competitions_loading } = this.props.competition;
    const { division, divisions_loading } = this.props.division;
    const { phase, phases_loading, phase_not_found } = this.props.phase;

    let dashboard_content = null;

    let loading_content = (
      <div className="container-fluid" align="center">
        <div className="projects-list px-3 py-5 p-md-5">
          <div id="down" className="projects-wrapper">
            <br></br>
            <Loading color="#80110f" height={'20%'} width={'20%'} />
          </div>
        </div>
      </div>
    );

    let searching_content = (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    );

    let phase_not_found_content = (
      <div className="container-fluid vh-100" align="center">
        <div className="projects-list h-100 d-flex flex-column justify-content-center align-items-center">
            <h2>{t ('no_register_phase')}</h2>
            <p>{t ('try_again_later')}</p>
            <button className='m-btn m-btn-theme m-btn-radius btn-lg w-75' onClick={() => this.onReturnToCompetition ()}>
              {t ('return')}
            </button>
        </div>
      </div>
    );

    let not_found_content = (
      <p style={{color: "red"}}>{t ('no_registered_email')}</p>
    );

    let bad_email_content = (
      <p style={{color: "red"}}>{t ('no_valid_email')}</p>
    );
  
    if (phase_not_found) {
      dashboard_content = phase_not_found_content;
    }
    else if (
      (account === null || loading)
      || (competition === null || competitions_loading)
      || (division === null || divisions_loading)
      || (phase === null || phases_loading)
    ) {
      dashboard_content = loading_content;
    }

    else {
      let tshirt_size = (
        <Fragment>
          <label className="col-md-12" align="left">
            {t ('enter_tshirt_size')} {t ('required')}
          </label>
          <div className="text-center input-group col-lg-12 mb-4">
            <div className="col-md-12 text-center">
              <Dropdown
                disabled={!this.state.search_email}
                options={tshirt_sizes}
                onChange={ (option) => this.onSelectSize ("tshirt_size", option)}
                value={this.state.tshirt_size}
                placeholder={t ('no_tshirt_size')}
              />

            </div>
          </div>
        </Fragment>
      );

      let pants_size = (
        <Fragment>
          <label className="col-md-12" align="left">
            {t ('enter_pants_size')} {t ('required')}
          </label>
          <div className="text-center input-group col-lg-12 mb-4">
            <div className="col-md-12 text-center">
              <Dropdown
                disabled={!this.state.search_email}
                options={pants_sizes}
                onChange={ (option) => this.onSelectSize ("pants_size", option)}
                value={this.state.pants_size}
                placeholder={t ('no_pants_size')}
              />

            </div>
          </div>
        </Fragment>
      );

      let bath_suit_type = (
        <Fragment>
          <label className="col-md-12" align="left">
            {t ('enter_bath_suit_type')} {t ('required')}
          </label>
          <div className="text-center input-group col-lg-12 mb-4">
            <div className="col-md-12 text-center">
              <Dropdown
                disabled={!this.state.search_email}
                options={bath_suit_types}
                onChange={ (option) => this.onSelectSize ("bath_suit_type", option)}
                value={this.state.bath_suit_type}
                placeholder={t ('no_bath_suit_type')}
              />

            </div>
          </div>
        </Fragment>
      );

      let bath_suit_size = (
        <Fragment>
          <label className="col-md-12" align="left">
            {t ('enter_bath_suit_size')} {t ('required')}
          </label>
          <div className="text-center input-group col-lg-12 mb-4">
            <div className="col-md-12 text-center">
              <Dropdown
                disabled={!this.state.search_email}
                options={bath_suit_sizes}
                onChange={ (option) => this.onSelectSize ("bath_suit_size", option)}
                value={this.state.bath_suit_size}
                placeholder={t ('no_bath_suit_size')}
              />

            </div>
          </div>
        </Fragment>
      );

      let proof_of_age = (
        <Fragment>
          <label className="col-md-12" align="left">
            {t('upload_age_evidence')} {t ('required')}
          </label>
          <div className="input-group col-lg-12 mb-4">
            <ImageInput
              name="document"
              disabled={!this.state.search_email}
              setFile={this.onDocumentUpload}
            />
          </div>
        </Fragment>
      );

      let payment_confirmation = (
      <Fragment>
        <label className="col-md-12" align="left">
          {t ('upload_payment_evidence')} {t ('required')}
        </label>
        <div className="input-group col-lg-12 mb-4">
          <ImageInput
            disabled={!this.state.search_email}
            name="document"
            setFile={this.onPaymentUpload}
          />
        </div>
      </Fragment>
      );

      let waiver_confirmation = (
        <div className='col-md-12 p-3'>
          <a href={`/api/uploads/competitions/${competition['_id']['$oid']}/${competition ['waiver']}`} target="_blank">{t ('read_waiver')}</a>
          <br/>
          <div className='row'>
            <div className='col-md-4 d-flex flex-column justify-content-center align-items-center my-3'>
            <input className="form-check-input" type="checkbox" name="waiver_confirmation" value ={!this.state.waiver_confirmation} checked={this.state.waiver_confirmation}
              id="waiver_confirmation_checkbox" 
              onChange={(e) => this.onChange ({"target": {
                "value": e.target.value === "true",
                "name": e.target.name
              }})}
            />
            </div> 
            <div className='col-md-8 p-2'>
              <p className='text-center'>{t ('waiver_disc', {"name": this.state.name})}</p>
            </div>
          </div>
          { errors.age && <div className="invalid-feedback">{ errors.waiver }</div> }
        </div>
      );

      let registration_content = (
      <div className="mb-5">
        <div className="card competition-card bg-gray">
          <div className="row no-gutters">
            <div className="col-lg-12">
              <div className="card-body">
                <h5 align="left">{t ('enter_personal_information')}</h5>

                <hr></hr>
                <div className='form-check'>
                  <input className="form-check-input" type="checkbox" value ={!this.state.its_me} checked={this.state.its_me}
                    id="its_meCheckBox" onChange={(e) => this.onItsMeCheck (e.target.value === "true")}
                  />
                  <label className="form-check-label" htmlFor="its_meCheckBox">
                    <h4>{t ('its_me')}</h4>
                  </label>
                </div>
                
                <div className="row">
                  {/* email */}
                  <label className="col-md-12" align="left">
                    {t ('enter_email')} {t ('required')}
                  </label>
                  <div className='row w-100 mb-4'>
                    <div className="input-group col-lg-10">
                      <div className="input-group-prepend">
                        <span className="input-group-text bg-white px-4 border-md border-right-0">
                          <i className="fas fa-at main-dark-blue"></i>
                        </span>
                      </div>

                      <input
                        className='form-control form-control-lg'
                        type="email"
                        placeholder="e.g. juan@timecapp.app"
                        name="email"
                        value={ this.state.email }
                        onChange={ this.onEmailChange } 
                          
                      />
                    </div>
                    <button className='col-lg-2 btn btn-sm btn-primary' onClick={() => this.onEmailSearch ()}>
                      {
                        this.state.searching
                        ? searching_content
                        : t ('search')
                      }
                    </button>
                  </div>

                  {
                    this.state.not_found
                    ? not_found_content
                    : this.state.bad_email
                      ? bad_email_content
                      : null
                  }

                  <div className='row col-md-12'>
                    {/* name */}
                    <div className='col-md-6'>
                      <label className="col-md-12" align="left">
                        {t ('enter_name')} {t ('required')}
                      </label>
                      <div className="input-group col-lg-12 mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-white px-4 border-md border-right-0">
                            <i className="fas fa-user main-dark-blue"></i>
                          </span>
                        </div>

                        <input
                          className={ classnames('form-control form-control-lg', {
                            'is-invalid': errors.first_name
                          })}
                          type="name"
                          disabled={!this.state.search_email}
                          placeholder="e.g. Eduardo"
                          name="first_name"
                          value={ this.state.first_name }
                          onChange={ this.onChange }
                        />
                        { errors.name && <div className="invalid-feedback">{ errors.first_name }</div> }
                      </div>
                    </div>
                    {/* lastname */}
                    <div className='col-md-6'>
                      <label className="col-md-12" align="left">
                        {t ('enter_lastname')} {t ('required')}
                      </label>
                      <div className="input-group col-lg-12 mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-white px-4 border-md border-right-0">
                            <i className="fas fa-user main-dark-blue"></i>
                          </span>
                        </div>

                        <input
                          className={ classnames('form-control form-control-lg', {
                            'is-invalid': errors.last_name
                          })}
                          type="name"
                          disabled={!this.state.search_email}
                          placeholder="e.g. Ovalle"
                          name="last_name"
                          value={ this.state.last_name }
                          onChange={ this.onChange }
                        />
                        { errors.name && <div className="invalid-feedback">{ errors.last_name }</div> }
                      </div>
                    </div>
                  </div>
                  
                  {/*username*/}
                  <label className="col-md-12" align="left">
                    {t ('enter_username')} {t ('required')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white px-4 border-md border-right-0">
                        <i className="fas fa-user main-dark-blue"></i>
                      </span>
                    </div>

                    <input
                      className={ classnames('form-control form-control-lg', {
                        'is-invalid': errors.name
                      })}
                      type="name"
                      disabled={!this.state.search_email}
                      placeholder="e.g. Eduardo Ovalle"
                      name="name"
                      value={ this.state.name }
                      onChange={ this.onChange }
                    />
                    { errors.name && <div className="invalid-feedback">{ errors.name }</div> }
                  </div>

                  {/* user image */}
                  <label className="col-md-12" align="left">
                    {t ('upload_your_photo')} {t ('optional')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <ImageInput
                      name="user_image"
                      disabled={!this.state.search_email}
                      setFile={this.onImageUpload}
                    />
                    
                    { errors.user_image && <div className="invalid-feedback">{ errors.user_image }</div> }
                  </div>

                  {/* box */}
                  <label className="col-md-12" align="left">
                    {t ('enter_box')} {t ('required')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white px-4 border-md border-right-0">
                        <i className="fas fa-dumbbell main-dark-blue"></i>
                      </span>
                    </div>

                    <input
                      className={ classnames('form-control form-control-lg', {
                        'is-invalid': errors.box
                      })}
                      type="box"
                      disabled={!this.state.search_email}
                      placeholder="e.g. Vista Fitness"
                      name="box"
                      value={ this.state.box }
                      required={true}
                      onChange={ (e)=> this.onChange(e, true) }
                    />
                    { errors.box && <div className="invalid-feedback">{ errors.box }</div> }
                  </div>

                  {/* box image */}
                  <label className="col-md-12" align="left">
                    {t ('box_photo')} {t ('optional')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <ImageInput
                      name="box_image"
                      disabled={!this.state.search_email}
                      setFile={this.onBoxImageUpload}
                    />
                    
                    { errors.box_image && <div className="invalid-feedback">{ errors.box_image }</div> }
                  </div>

                  {/* age */}
                  <label className="col-md-12" align="left">
                    {t ('enter_age')} {t ('required')}
                  </label>
                    <div className="input-group col-lg-12 mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white px-4 border-md border-right-0">
                        <i className="fas fa-calendar main-dark-blue"></i>
                      </span>
                    </div>

                    <input
                      className={ classnames('form-control form-control-lg', {
                        'is-invalid': errors.age
                      })}
                      type="number"
                      disabled={!this.state.search_email}
                      placeholder="e.g. 18"
                      name="age"
                      value={ this.state.age }
                      onChange={ this.onChange }
                    />
                    { errors.age && <div className="invalid-feedback">{ errors.age }</div> }
                  </div>

                  {/* gender */}
                  <label className="col-md-12" align="left">
                    {t ('enter_gender')} {t ('required')}
                  </label>
                  <div className="text-center input-group col-lg-12 mb-4">
                    <div className="col-md-12 text-center">
                      <Dropdown
                        options={genders}
                        disabled={!this.state.search_email}
                        onChange={this.onSelectGender}
                        value={this.state.gender}
                        placeholder="No Gender"
                      />

                    </div>
                  </div>

                  {/* country */}
                  <label className="col-md-12" align="left">
                    {t ('enter_country')} {t ('required')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <div className="col-md-12 text-center">
                      <CountrySelector
                        placeholder="Select your country"
                        name="country"
                        disabled={!this.state.search_email}
                        value={this.state.country}
                        onChange={this.onSelectCountry}
                      />
                    </div>

                    { errors.country && <div className="invalid-feedback">{ errors.country }</div> }
                  </div>

                  {/* tshirt_size */}
                  { phase.tshirt_size === true && tshirt_size }

                  {/* pants_size */}
                  { phase.pants_size === true && pants_size }

                  {/* bath_suit_type */}
                  { phase.bath_suit_type === true && bath_suit_type }

                  {/* bath_suit_size */}
                  { phase.bath_suit_size === true && bath_suit_size }

                  <br></br>
                  <br></br>
                  <br></br>
                  
                  {/* proof_of_age */}
                  { phase.requires_age_proof === true && proof_of_age }

                  {/* payment_confirmation */}
                  { phase.payment_confirmation === true && payment_confirmation }

                  <br></br>
                  <br></br>

                  {phase.requires_insurance === true 
                    && (
                      <h3>{t ('competition_offers_insurance')}</h3>
                    )
                  }

                  {/* waiver_confirmation */}
                  { phase.requires_waiver && waiver_confirmation }

                  <br></br>
                  <br></br>
                  <div className="form-group col-lg-4 mx-auto mb-0 text-center">
                    <button
                      className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px"
                      onClick={this.onFormSubmit}
                    >
                      {
                        phase.requires_payment ? t ('confirm_and_pay')
                        : t ('confirm_and_submit')
                      }
                    </button>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      );

      dashboard_content = (
      <div>
        <CompetitionHeader competition={competition}/>

        <br></br>
        <br></br>

        <div className="container">
          <div className="align-items-center">
            <div className="text-center">
              <div className="col-8" align="left">
                <h2 className="main-red">{t ('selected_category')}</h2>
              </div>

              <Division division={division}/>

              <div className="col-8" align="left">
                <h2 className="main-red">{t ('registration_phase')}</h2>
              </div>  

              <RegistrationPhase phase={phase}/>

              <div className="col-8" align="left">
                <h2 className="main-red">{t ('your_register')}</h2>
              </div>

              { registration_content }

            </div>
          </div>
        </div>
      </div>
      );
    }

    return (
    <div>
      <Navbar />

      <DashboardNav />

      <div className="main-wrapper">
        { dashboard_content }

        <br></br>

        <Footer />
      </div>
    </div>
    );
  }

}

AthleteRegistrationInput.propTypes = {
  success: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  competition: PropTypes.object.isRequired,
  division: PropTypes.object.isRequired,
  phase: PropTypes.object.isRequired,
  checkout: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success,
  athletes: state.athletes,
  auth: state.auth,
  account: state.account,
  account_get: PropTypes.func.isRequired,
  checkout_session: PropTypes.func.isRequired,
  competition: state.competition,
  competition_get_info: PropTypes.func.isRequired,
  division: state.division,
  division_get_info: PropTypes.func.isRequired,
  phase: state.phase,
  phase_get_current: PropTypes.func.isRequired,
  checkout: state.checkout,
  user_session_token: PropTypes.func.isRequired
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    {
      account_get,
      checkout_session,
      competition_get_info,
      division_get_info,
      phase_get_current,
      user_session_token,
      athlete_search_email,
      athlete_clean_search_data
    }
  )
) (withRouter (AthleteRegistrationInput));
