import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import Moment from 'react-moment';

import {
  registration_status_string
} from '../../../actions/registrationsActions';

class AthleteRegistration extends Component {

  render () {
    let { registration } = this.props;
    const { t } =this.props;

    let registration_status = registration_status_string (registration.status, t);

    return (
    <div className="mb-5">
      <div className="card competition-card bg-gray">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="card-body">
              <h5>{t ('you_have_already_registered')}</h5>

              <h3>Status: { registration_status }</h3>

              <h5>Date: <Moment utc={true} format={t ('day_month_year')}>{ registration.date["$date"] }</Moment> </h5>
      
              <hr></hr>

              <div className="row">
                <div className="col-sm-6">
                  <p className="font-20px"><strong>{t ('name')}: </strong>{ registration.name }</p>
                </div>
                <div className="col-sm-6">
                  <p className="font-20px"><strong>{t ('box')}: </strong>{ registration.box }</p>
                </div>
                <div className="col-sm">
                </div>
              </div>

              <p className="font-24px">{t ('you_are_registered_to')}<strong>{ registration.division.name }</strong></p>

              <p className="font-24px">{t ('any_further_updates_will_be_sent_to')}<strong>{ registration.email }</strong></p>

              <p>{t ('please_consult_results_in_timecapp')}</p>
              <p>{t ('contact_us_at')}<strong>timecappstadium@gmail.com</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

}

AthleteRegistration.propTypes = {
  registration: PropTypes.object.isRequired
};

export default  withTranslation ()(AthleteRegistration);
