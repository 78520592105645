import {
  GET_TEAM_REGISTRATION,
  TEAM_REGISTRATION_LOADING
} from '../actions/types';

const initial_state = {
  team_registration: null,
  team_registrations_loading: false
};

export default function (state = initial_state, action) {

  switch (action.type) {
    case TEAM_REGISTRATION_LOADING:
    return {
      ...state,
      loading: true
    };
    case GET_TEAM_REGISTRATION:
    return {
      ...state,
      team_registration: action.payload,
      loading: false
    };
    default: return state;
  }

}
