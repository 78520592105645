import classnames from 'classnames';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import getAgeFromDate from '../../../utils/dateToAge';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import 'react-dropdown/style.css';

import Footer from '../../Footer';
import Navbar from '../../Navbar';

import Loading from '../../common/Loading';

import DashboardNav from '../DashboardNav';
import CompetitionHeader from './CompetitionHeader';
import Division from './Division';
import MemberRegistrationInput from './MemberRegistrationInput';
import RegistrationPhase from './RegistrationPhase';

import ImageInput from '../../input/ImageInput';

import { account_get } from '../../../actions/accountActions';
import { user_session_token } from '../../../actions/authActions';
import { checkout_team_session } from '../../../actions/checkoutActions';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { competition_get_info } from '../../../actions/competitionsActions';
import { division_get_info } from '../../../actions/divisionsActions';
import { phase_get_current } from '../../../actions/phasesActions';

class TeamRegistrationInput extends Component {
  
  constructor () {
    super ();

    this.state = {
      session_token: null,
      mobile: null,

      competition_id: '',
      phase_id: '',
      division: '',

      name: '',
      team_image: null,

      box: '',
      box_image: null,

      members: [],

      payment: null,

      coach: false,

      its_me: -1,
      waiver_confirmation: false,

      errors: {}
    }
    this.getEmptyMember = this.getEmptyMember.bind (this);
    this.onChange = this.onChange.bind (this);
    this.onImageUpload = this.onImageUpload.bind (this);
    this.onBoxImageUpload = this.onBoxImageUpload.bind (this);

    this.onMemberChange = this.onMemberChange.bind (this);
    this.onSelectGender = this.onSelectGender.bind (this);
    this.onSelectCountry = this.onSelectCountry.bind (this);
    this.onSelectSize = this.onSelectSize.bind (this);

    this.onPaymentUpload = this.onPaymentUpload.bind (this);
    this.onFormSubmit = this.onFormSubmit.bind (this);
    this.onItsMeCheck = this.onItsMeCheck.bind (this);
    this.onReturnToCompetition = this.onReturnToCompetition.bind (this);
    this.onUpdateMember = this.onUpdateMember.bind (this);
    this.email_in_team = this.email_in_team.bind (this);
  }

  getEmptyMember (idx) {
    return {
      idx: idx, first_name: "", last_name: "", name: "",
      email: "", age: "", gender: 0, country: "", tshirt_size: 0, pants_size: 0, bath_suit_type: 0, bath_suit_size: 0
    }
  }

  componentDidMount () {
    let competition = this.props.match.params.id;

    let params = new URLSearchParams (this.props.location.search);
    let division = params.get ('division');
    let session_token = params.get ('token');
    let mobile = params.get ("mobile");

    this.setState ({
      division: division,
      session_token: session_token,
      mobile: mobile
    });

    // handle user session token
    this.props.user_session_token (session_token);

    // fetch the user's main information
    this.props.account_get (session_token);

    // fetch general competition's information
    this.props.competition_get_info (competition, session_token);

    // fetch the selected division's information
    this.props.division_get_info (competition, division, session_token);

    // fetch the competition's registration phases
    this.props.phase_get_current (competition, division, session_token);
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState ({ errors: nextProps.errors });
    }

    if (
      (nextProps.competition.competition === null)
      && this.props.competition.competitions_loading
      && !this.props.account.loading
    ) {
      this.props.history.push ('/404');
    }

    if (
      (nextProps.division.division !== null)
      && !this.props.division.divisions_loading
      && (this.state.members === undefined || this.state.members.length === 0)
    ) {
      let members = [];
      for (let idx = 0; idx < nextProps.division.division.n_athletes; idx++) {
        members.push (Object.assign ({}, this.getEmptyMember (idx)));
      }

      this.setState ({members: members});
    }

    if (nextProps.checkout.checkout !== null) {
      // redirect to stripe's checkout
      // console.log (nextProps.checkout.checkout);
      window.location.href = nextProps.checkout.checkout.checkout_session_url;
    }
  }

  onChange (e, uppercase = false) {
    let value = e.target.value;

    if (uppercase) {
      value = value.toUpperCase ();
    }
    this.setState ({[e.target.name]: value});
  }

  onImageUpload (value) {
    this.setState ({team_image: value});
  }

  onBoxImageUpload (value) {
    this.setState ({box_image: value});
  }

  onMemberChange (e, idx) {
    const rest = [...this.state.members];
    rest[idx][e.target.name] = e.target.value;
    this.setState ({members: rest});
  }

  onSelectGender (e, idx) {
    const rest = [...this.state.members];
    rest[idx]["gender"] = e;
    this.setState ({members: rest});
  }

  onUpdateMember (data, idx) {
    let members = [...this.state.members];
    if (data === null) {
      data = this.getEmptyMember (idx);

      if (idx === this.state.its_me) {
        this.setState({
          its_me: -1
        })
      }
    } 

    for (const key in data) {
      members [idx][key] = data[key];
    }

    this.setState({"members": members})
  }

  onSelectCountry (e, idx) {
    const rest = [...this.state.members];
    rest[idx]["country"] = e;
    this.setState ({members: rest});
  }

  onSelectSize (name, e, idx) {
    const bath_fields = ["bath_suit_type", "bath_suit_size"];
    const rest = [...this.state.members];
    if (bath_fields.includes (name) && e.label === "NA") {
      console.log ("Hola?");
      rest [idx]["bath_suit_type"] = {value: 3, label: "NA"};
      rest [idx]["bath_suit_size"] = {value: 6, label: "NA"}
    } else {
      rest[idx][name] = e;
    }
    this.setState ({members: rest});
  }

  onPaymentUpload (value) {
    this.setState ({payment: value});
  }

  onItsMeCheck (value) {
    let new_idx = value;
    let account = this.props.account.account;

    // If current value is the same is saved, restart to -1
    if (value === this.state.its_me) {
      new_idx = -1;
    }

    this.setState({its_me: new_idx});

    const genders = [
      { value: 0, label: "None" },
      { value: 1, label: "Male" },
      { value: 2, label: "Female" },
      { value: 3, label: "Other" },
    ];

    let members = this.state.members;

    //Find if another user has its_me flag on and turn it off
    let my_member = members.findIndex ((member) => member.email === account.email);

    if (my_member !== undefined) {
      members [my_member] = this.getEmptyMember (my_member);
    }

    // If idx is -1 then clean the current value member
    if (new_idx === -1) {
      members[value] = this.getEmptyMember (value);
    } else {
      let age = getAgeFromDate (account.birth_date["$date"]);

      members [value] = {
        idx: value,
        name: `${account.name.split (" ")[0]} ${account.surname.split (" ")[0]}`,
        first_name: account.name,
        last_name: account.surname,
        email: account.email,
        age: age,
        gender: genders [account.gender],
        tshirt_size: {
          label: "",
          value: -1
        },
        pants_size: {
          label: "",
          value: -1
        },
        bath_suit_type: {
          label: "",
          value: -1
        },
        bath_suit_size: {
          label: "",
          value: -1
        },
      }

      this.setState({"members": members});
    }
    
  }

  onReturnToCompetition () {
    let competition = this.props.match.params.id;
    window.location.href = `/competitions/${competition}/info`;
  }

  onFormSubmit (e) {
    e.preventDefault ();

    const { phase } = this.props.phase;

    const form_data = new FormData ();
    form_data.append ("competition", this.props.competition.competition._id["$oid"]);
    form_data.append ("phase", this.props.phase.phase._id["$oid"]);
    form_data.append ("division", this.state.division);

    if (this.state.mobile !== null) {
      form_data.append ("mobile", this.state.mobile);
    }

    form_data.append ("name", this.state.name);

    if (this.state.team_image !== null) {
      form_data.append ("team_image", this.state.team_image);
    }

    form_data.append ("box", this.state.box);

    if (this.state.box_image !== null) {
      form_data.append ("box_image", this.state.box_image);
    }

    if (phase.payment_confirmation && (this.state.payment !== null)) {
      form_data.append ("payment", this.state.payment);
    }

    form_data.append ("coach", this.state.coach);

    form_data.append ("its_me", this.state.its_me); 

    form_data.append ("n_members", this.state.members.length);
    form_data.append ("waiver_confirmation", this.state.waiver_confirmation);

    this.state.members.forEach ((member, i) => {
      form_data.append (`member-${member.idx}-first_name`, member["first_name"]);
      form_data.append (`member-${member.idx}-last_name`, member["last_name"]);
      form_data.append (`member-${member.idx}-name`, member["name"]);
      form_data.append (`member-${member.idx}-email`, member["email"]);
      form_data.append (`member-${member.idx}-age`, member["age"]);
      form_data.append (`member-${member.idx}-gender`, member["gender"].value);
      form_data.append (`member-${member.idx}-country`, member["country"].label);
      form_data.append (`member-${member.idx}-tshirt_size`, member["tshirt_size"].value);
      form_data.append (`member-${member.idx}-pants_size`, member["pants_size"].value);
      form_data.append (`member-${member.idx}-bath_suit_type`, member["bath_suit_type"].value);
      form_data.append (`member-${member.idx}-bath_suit_size`, member["bath_suit_size"].value);

    });

    this.props.checkout_team_session (form_data, this.state.session_token, this.props.t);
  }

  email_in_team (email, idx) {
    let retval = false;
    this.state.members.forEach ((member, i) => {
      if (member ["email"] === email && i !== idx) {
        retval = true;
      }
    })
    return retval;
  }

  render () {
    let { errors } = this.state;

    const { account, loading } = this.props.account;
    const { competition, competitions_loading } = this.props.competition;
    const { division, divisions_loading } = this.props.division;
    const { phase, phases_loading, phase_not_found } = this.props.phase;

    const { t } =this.props;

    let dashboard_content = null;

    let loading_content = (
      <div className="container-fluid" align="center">
        <div className="projects-list px-3 py-5 p-md-5">
          <div id="down" className="projects-wrapper">
            <br></br>
            <Loading color="#80110f" height={'20%'} width={'20%'} />
          </div>
        </div>
      </div>
    );

    let phase_not_found_content = (
      <div className="container-fluid vh-100" align="center">
        <div className="projects-list h-100 d-flex flex-column justify-content-center align-items-center">
            <h2>{t  ('no_register_phase')}</h2>
            <p>{t ('try_again_later')}</p>
            <button className='m-btn m-btn-theme m-btn-radius btn-lg w-75' onClick={() => this.onReturnToCompetition ()}>
              {t ('return')}
            </button>
        </div>
      </div>
    );

    if (phase_not_found) {
      dashboard_content = phase_not_found_content;
    } else if (
      (account === null || loading)
      || (competition === null || competitions_loading)
      || (division === null || divisions_loading)
      || (phase === null || phases_loading)
    ) {
      dashboard_content = loading_content;
    }

    else {
      let payment_confirmation = (
      <Fragment>
        <h5 className="col-md-12" align="left">
          {t ('upload_payment_evidence')} {t ('required')}
        </h5>
        <div className="input-group col-lg-12 mb-4">
          <ImageInput
            name="document"
            setFile={this.onPaymentUpload}
          />
        </div>
      </Fragment>
      );
      
      let waiver_confirmation = (
        <div className='col-md-12 p-3'>
          <a href={`/api/uploads/competitions/${competition['_id']['$oid']}/${competition ['waiver']}`} target="_blank">{t ('read_waiver')}</a>
          <br/>
          <div className='row'>
          <div className='col-md-4 d-flex flex-column justify-content-center align-items-center my-2'>
            <input className="form-check-input" type="checkbox" name="waiver_confirmation" value ={!this.state.waiver_confirmation} checked={this.state.waiver_confirmation}
              id="waiver_confirmation_checkbox" 
              onChange={(e) => this.onChange ({"target": {
                "value": e.target.value === "true",
                "name": e.target.name
              }})}
            />
            </div> 
            <div className='col-md-8 p-2'>
              <p>{t ('waiver_disc', {'name': this.state.members[0].name})}</p>
            </div>
          </div>
          { errors.age && <div className="invalid-feedback">{ errors.waiver }</div> }
        </div>
      );

      let registration_content = (
      <div className="mb-5">
        <div className="card competition-card bg-gray">
          <div className="row no-gutters">
            <div className="col-lg-12">
              <div className="card-body">
                <h5 align="left">{t ('enter_team_information')}</h5>

                <hr></hr>
            
                <div className="row">
                  <div className='form-check text-center col-md-12'>
                    <input 
                      className="form-check-input" 
                      id={`coach-checkbox`} 
                      type="checkbox" 
                      value={!this.state.coach}
                      checked={this.state.coach}
                      onChange={(e) => this.setState({"coach": e.target.value === "true"})}
                    />
                    <label className="form-check-label" htmlFor={`coach-checkbox`}>
                      <h5>{t ('im_coach')}</h5>
                    </label>
                  </div>
                  <div className='col-md-12'><hr></hr></div>
                  {/* name */}
                  <label className="col-md-12" align="left">
                  {t ('team_name')} {t ('required')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white px-4 border-md border-right-0">
                        <i className="fas fa-user main-dark-blue"></i>
                      </span>
                    </div>

                    <input
                      className={ classnames('form-control form-control-lg', {
                        'is-invalid': errors.name
                      })}
                      type="name"
                      placeholder="e.g. Dream Team"
                      name="name"
                      value={ this.state.name }
                      onChange={ (e) => this.onChange (e, true) }
                    />
                    { errors.name && <div className="invalid-feedback">{ errors.name }</div> }
                  </div>

                  {/* team image */}
                  <label className="col-md-12" align="left">
                    {t ('team_photo')} {t ('optional')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <ImageInput
                      name="team_image"
                      setFile={this.onImageUpload}
                    />

                    { errors.team_image && <div className="invalid-feedback">{ errors.team_image }</div> }
                  </div>

                  {/* box */}
                  <label className="col-md-12" align="left">
                    {t ('enter_box')} {t ('required')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white px-4 border-md border-right-0">
                        <i className="fas fa-dumbbell main-dark-blue"></i>
                      </span>
                    </div>

                    <input
                      className={ classnames('form-control form-control-lg', {
                        'is-invalid': errors.box
                      })}
                      type="box"
                      placeholder="e.g. Vista Fitness"
                      name="box"
                      required={true}
                      value={ this.state.box }
                      onChange={ (e) => this.onChange(e, true) }
                    />
                    { errors.box && <div className="invalid-feedback">{ errors.box }</div> }
                  </div>

                  {/* box image */}
                  <label className="col-md-12" align="left">
                    {t ('box_photo')} {t ('optional')}
                  </label>
                  <div className="input-group col-lg-12 mb-4">
                    <ImageInput
                      name="box_image"
                      setFile={this.onBoxImageUpload}
                    />

                    { errors.box_image && <div className="invalid-feedback">{ errors.box_image }</div> }
                  </div>

                  {this.state.members.map ((member, idx) => {
                    return <MemberRegistrationInput
                      key={idx}
                      idx={idx + 1}
                      phase={phase}
                      first_name={member.first_name}
                      last_name={member.last_name}
                      name={member.name}
                      email={member.email}
                      age={member.age}
                      gender={member.gender}
                      country={member.country}
                      tshirt_size_value={member.tshirt_size}
                      pants_size_value={member.pants_size}
                      bath_suit_size_value={member.bath_suit_size}
                      bath_suit_type_value={member.bath_suit_type}
                      its_me={this.state.its_me}
                      coach={this.state.coach}
                      email_in_team= { (email) => this.email_in_team(email, idx) }
                      onChange={(e) => this.onMemberChange (e, idx)}
                      onItsMeCheck={(idx) => this.onItsMeCheck (idx)}
                      onSelectGender={(e) => this.onSelectGender (e, idx)}
                      onSelectCountry={(e) => this.onSelectCountry (e, idx)}
                      onSelectSize={(name, e) => this.onSelectSize (name, e, idx)} 
                      updateMemberData={(data) => this.onUpdateMember (data, idx)}
                    />
                  })}

                  <br></br>
                  <br></br>

                  {/* payment_confirmation */}
                  { phase.payment_confirmation && payment_confirmation }

                  <br></br>
                  <br></br>
                  
                  {phase.requires_insurance === true 
                    && (
                      <h3>{t ('competition_offers_insurance')}</h3>
                    )
                  }

                  { 
                    phase.requires_waiver === true && this.state.members.length > 0
                    && waiver_confirmation
                  }

                  <br></br>
                  <br></br>

                  <div className="form-group col-lg-4 mx-auto mb-0 text-center">
                    <button
                      className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px"
                      onClick={(e) => this.onFormSubmit(e)}
                    >
                      {
                        phase.requires_payment 
                        ? t ('confirm_n_checkout')
                        : t ('confirm_n_send')
                      }
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      );

      dashboard_content = (
      <div>
        <CompetitionHeader competition={competition}/>

        <br></br>
        <br></br>

        <div className="container">
          <div className="align-items-center">
            <div className="text-center">
              <div className="col-8" align="left">
                <h2 className="main-red">{t ('selected_category')}</h2>
              </div>

              <Division division={division}/>

              <div className="col-8" align="left">
                <h2 className="main-red">{t ('registration_phase')}</h2>
              </div>

              <RegistrationPhase phase={phase}/>

              <div className="col-8" align="left">
                <h2 className="main-red">{t ('your_register')}</h2>
              </div>

              { registration_content }

            </div>
          </div>
        </div>
      </div>
      );
    }

    return (
    <div>
      <Navbar />

      <DashboardNav />

      <div className="main-wrapper">
        { dashboard_content }

        <br></br>

        <Footer />
      </div>
    </div>
    );
  }

}

TeamRegistrationInput.propTypes = {
  success: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  competition: PropTypes.object.isRequired,
  division: PropTypes.object.isRequired,
  phase: PropTypes.object.isRequired,
  checkout: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success,
  auth: state.auth,
  account: state.account,
  account_get: PropTypes.func.isRequired,
  checkout_team_session: PropTypes.func.isRequired,
  competition: state.competition,
  competition_get_info: PropTypes.func.isRequired,
  division: state.division,
  division_get_info: PropTypes.func.isRequired,
  phase: state.phase,
  phase_get_current: PropTypes.func.isRequired,
  checkout: state.checkout,
  user_session_token: PropTypes.func.isRequired
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    {
      account_get,
      checkout_team_session,
      competition_get_info,
      division_get_info,
      phase_get_current,
      user_session_token
    }
  )
) (withRouter (TeamRegistrationInput));
