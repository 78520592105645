import isEmpty from '../utils/isEmpty';

import { SET_CURRENT_USER, SET_USER_ADMIN } from '../actions/types';

let initial_state = {
  isAuthenticated: false,
  user: {},
  isAdmin: false
};

export default function (state=initial_state, action) {

  switch (action.type) {
    case SET_CURRENT_USER: 
      return {
        ...state,
        isAuthenticated: !isEmpty (action.payload),
        user: action.payload
      }
    case SET_USER_ADMIN:
      return {
        ...state,
        isAdmin: action.payload
      }

    default: return state;
  }

}
