import { combineReducers } from 'redux';

import errorsReducer from './errorsReducer';
import successReducer from './successReducer';
import alertReducer from './alertsReducer';

import athletesReducer from './athletesReducer';
import authReducer from './authReducer';
import accountReducer from './accountReducer';

import competitionsReducer from './competitionsReducer';
import divisionsReducer from './divisionsReducer';
import registrationsReducer from './registrationsReducer';
import teamsReducer from './teamsReducer';
import membersReducer from './membersReducer';
import phasesReducer from './phasesReducer';
import checkoutReducer from './checkoutReducer';

export default combineReducers ({

	errors: errorsReducer,
	success: successReducer,
	alert: alertReducer,

	athletes: athletesReducer,
	auth: authReducer,
	account: accountReducer,

	competition: competitionsReducer,
	division: divisionsReducer,
	registration: registrationsReducer,
	team: teamsReducer,
	member: membersReducer,
	phase: phasesReducer,
	checkout: checkoutReducer,

});
