export const ERRORS_GET = "ERRORS_GET";
export const ERRORS_CLEAR = "ERRORS_CLEAR";

export const SUCCESS_GET = "SUCCESS_GET";
export const SUCCESS_CLEAR = "SUCCESS_CLEAR";

export const ALERT_SET = "ALERT_SET";
export const ALERT_REMOVE = "ALERT_REMOVE";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_ADMIN = "SET_USER_ADMIN";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND";
export const CLEAR_CURRENT_ACCOUNT = "CLEAR_CURRENT_ACCOUNT";

export const PROFILE_LOADING = 'PROFILE_LOADING';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';

export const COMPETITION_LOADING = "COMPETITION_LOADING";
export const GET_COMPETITIONS = "GET_COMPETITIONS";
export const GET_COMPETITION = "GET_COMPETITION";

export const DIVISION_LOADING = "DIVISION_LOADING";
export const GET_DIVISION = "GET_DIVISION";

export const REGISTRATION_LOADING = "REGISTRATION_LOADING";
export const GET_REGISTRATION = "GET_REGISTRATION";
export const REGISTRATION_VALIDATING = "REGISTRATION_VALIDATING";
export const GET_REGISTRATION_VALIDATION = "GET_REGISTRATION_VALIDATION";

export const TEAM_REGISTRATION_LOADING = "TEAM_REGISTRATION_LOADING";
export const GET_TEAM_REGISTRATION = "GET_TEAM_REGISTRATION";

export const MEMBER_REGISTRATION_LOADING = "MEMBER_REGISTRATION_LOADING";
export const GET_MEMBER_REGISTRATION = "GET_MEMBER_REGISTRATION";

export const MEMBER_REGISTRATION_SEARCH_EMAIL_LOADING = "MEMBER_REGISTRATION_SEARCH_EMAIL_LOADING";
export const GET_MEMBER_REGISTRATION_SEARCH_EMAIL = "GET_MEMBER_REGISTRATION_SEARCH_EMAIL";
export const GET_MEMBER_REGISTRATION_SEARCH_EMAIL_NOT_FOUND = "GET_MEMBER_REGISTRATION_SEARCH_EMAIL_NOT_FOUND";

export const PHASE_LOADING = "PHASE_LOADING";
export const GET_PHASE = "GET_PHASE";
export const GET_PHASE_NOT_FOUND = "GET_PHASE_NOT_FOUND";

export const CHECKOUT_LOADING = "CHECKOUT_LOADING";
export const GET_CHECKOUT = "GET_CHECKOUT";

export const ATHLETE_SEARCH_EMAIL_LOADING = "ATHLETE_SEARCH_EMAIL_LOADING";
export const GET_ATHLETE_SEARCH_EMAIL = "GET_ATHLETE_SEARCH_EMAIL";
export const GET_ATHLETE_SEARCH_EMAIL_NOT_FOUND = "GET_ATHLETE_SEARCH_EMAIL_NOT_FOUND";