import axios from 'axios';

import {
  PHASE_LOADING, GET_PHASE, GET_PHASE_NOT_FOUND
} from './types';

export const phase_type_string = (type) => {

  let phase_type = null;
  switch (type) {
    case 0: phase_type = "None"; break;
    case 1: phase_type = "All"; break;
    case 2: phase_type = "Selected"; break;

    default: phase_type = "Undefined"; break;
  }

  return phase_type;

}

export const phase_status_string = (status) => {

  let phase_status = null;
  switch (status) {
    case 0: phase_status = "None"; break;
    case 1: phase_status = "Coming soon..."; break;
    case 2: phase_status = "Available"; break;
    case 3: phase_status = "Sold out!"; break;
    case 4: phase_status = "Ended"; break;

    default: phase_status = "Undefined!"; break;
  }

  return phase_status;

}

export const phase_get_current = (
  competition, division, session_token
) => dispatch => {

  const config = {
    params: {
      competition: competition,
      division: division
    }
  };

  if (session_token !== null) {
    config["headers"] = { Authorization: `Bearer ${session_token}` }
  }

  dispatch (phases_set_loading ());

  axios.get (
    "/api/registrations/phases/current",
    config
  )
    .then (res =>
      dispatch ({
        type: GET_PHASE,
        payload: res.data
      }))
    .catch (err =>
      dispatch ({
        type: GET_PHASE_NOT_FOUND,
        payload: null
      }));

}

export const phases_set_loading = () => {
  return {
    type: PHASE_LOADING
  };
};
