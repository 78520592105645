import classnames from 'classnames';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import sha256 from 'crypto-js/sha256';

import Footer from '../Footer';
import Navbar from '../Navbar';

import Loading from '../common/Loading';

import DashboardNav from './DashboardNav';

// my actions
import { account_get, email_change } from '../../actions/accountActions';
import { password_change, user_delete } from '../../actions/authActions';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import isEmpty from '../../utils/isEmpty';
import { validate } from 'email-validator';

class Header extends Component {

  render () {
    return (
      <section className="gray-bg docs-header">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <div className="p-60px-b">
                <h1 className="main-purple font-w-700 font-60px md-font-40px sm-font-30px">Settings</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

class Settings extends Component {

	constructor () {
		super ();
		this.state = {
      email: '',
      current: '',
      password: '',
      confirm: '',
      bad_email: '',
			errors: {}
		}

    this.onChange = this.onChange.bind (this);
    this.onChangeEmail = this.onChangeEmail.bind (this);
    this.onChangePassword = this.onChangePassword.bind (this);
	}

	componentDidMount () { this.props.account_get (null); }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState ({ errors: nextProps.errors });
    }

    if (!isEmpty (nextProps.success)) {
      // window.location.reload (false);
      this.setState ({ current: '', password: '', confirm: '' });
      this.props.account_get (null);
    }
  }

  onChange (e) { this.setState ({ [e.target.name]: e.target.value }); }

  onChangeEmail (e) {
    e.preventDefault ();
    let bad_email = validate (this.state.email);
    if (!bad_email) {
        this.props.email_change ({
          email: this.state.email
        });
    }
    this.setState ({
      "bad_email": bad_email
    })
  }

  onChangePassword (e) {
    e.preventDefault ();

    this.props.password_change ({
      current: isEmpty (this.state.current) ? '' : sha256 (this.state.current).toString (),
      password: isEmpty (this.state.password) ? '' : sha256 (this.state.password).toString (),
      confirm: isEmpty (this.state.confirm) ? '' : sha256 (this.state.confirm).toString ()
    });
  }

	render () {
    let { errors } = this.state;

		const { account, loading } = this.props.account;

    const { t } =this.props;

    let bad_email_content = (
      <p style={{color: "red"}}>{t ('no_valid_email')}</p>
    );

    let dashboard_content;
    if (account === null || loading) {
      dashboard_content = (
        <div className="container-fluid" align="center">
          <div className="projects-list px-3 py-5 p-md-5">
            <div id="down" className="projects-wrapper">
              <br></br>
              <Loading color="#80110f" height={'20%'} width={'20%'} />
            </div>
          </div>
        </div>
      );
    }

    else {
      dashboard_content = (
        <div className="container">
					<div className="py-5 mt-4 align-items-center">
            {/* email */}
            <form noValidate onSubmit={ this.onChangeEmail }>
              <div className="row">
                <label className="col-md-12">
                  {t ('will_be_used_for_notifications')}
                </label>

                <div className="input-group col-lg-12 mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white px-4 border-md border-right-0">
                      <i className="fas fa-at main-dark-blue"></i>
                    </span>
                  </div>

                  <input
                    className={ classnames('form-control form-control-lg', {
                      'is-invalid': errors.email
                    })}
                    type="email"
                    placeholder={ account.email }
                    name="email"
                    value={ this.state.email }
                    onChange={ this.onChange }
                  />
                  { errors.email && <div className="invalid-feedback">{ errors.email }</div> }
                  { this.state.bad_email ? bad_email_content : null }

                </div>

                <div className="form-group col-lg-4 mx-auto mb-0">
                  <button type="submit" className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px">
                    { t ('change_email')}
                  </button>
                </div>
              </div>
            </form>

            <br></br>
            <br></br>
            <br></br>

            {/* password */}
            <form noValidate onSubmit={ this.onChangePassword }>
              <div className="row">
                <label className="col-md-12">
                  {t ('change_password')}
                </label>

                {/* current */}
                <div className="input-group col-lg-12 mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white px-4 border-md border-right-0">
                      <i className="fas fa-check-circle main-dark-blue"></i>
                    </span>
                  </div>

                  <input
                    className={ classnames('form-control form-control-lg', {
                      'is-invalid': errors.current
                    })}
                    type="password"
                    placeholder="Current password"
                    name="current"
                    value={ this.state.current }
                    onChange={ this.onChange }
                  />
                  { errors.current && <div className="invalid-feedback">{ errors.current }</div> }

                </div>

                {/* password */}
                <div className="input-group col-lg-12 mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white px-4 border-md border-right-0">
                      <i className="fas fa-lock main-dark-blue"></i>
                    </span>
                  </div>

                  <input
                    className={ classnames('form-control form-control-lg', {
                      'is-invalid': errors.password
                    })}
                    type="password"
                    placeholder="New password"
                    name="password"
                    value={ this.state.password }
                    onChange={ this.onChange }
                  />
                  { errors.password && <div className="invalid-feedback">{ errors.password }</div> }

                </div>

                {/* confirm */}
                <div className="input-group col-lg-12 mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white px-4 border-md border-right-0">
                      <i className="fas fa-check-circle main-dark-blue"></i>
                    </span>
                  </div>

                  <input
                    className={ classnames('form-control form-control-lg', {
                      'is-invalid': errors.confirm
                    })}
                    type="password"
                    placeholder="Confirm password"
                    name="confirm"
                    value={ this.state.confirm }
                    onChange={ this.onChange }
                  />
                  { errors.confirm && <div className="invalid-feedback">{ errors.confirm }</div> }

                </div>

                <div className="form-group col-lg-4 mx-auto mb-0">
                  <button type="submit" className="m-btn m-btn-theme m-btn-radius btn-lg w-100 font-16px">
                    {t ('change_current_password')}
                  </button>
                </div>
              </div>
            </form>
					</div>

          <div className="align-items-center">
						<div className="row">
							<h3 className="main-red">{t ('danger_zone')}</h3>

							<br></br>
							<br></br>
							<br></br>

							<div className="row">
								<div className="col-8">
									<h4 className="main-red">{t ('delete_account')}</h4>
									<p>{t ('delete_account_disc')}</p>
								</div>
								<div className="col-4 text-center">
									<button type="button" className="btn btn-danger" onClick={() => this.props.user_delete () }>{t ('delete_account')}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
      );
    }

		return (
		<div>
			<Navbar />

			<DashboardNav />

			<div className="main-wrapper">
				<Header />

				{ dashboard_content }

        <br></br>

				<Footer />
			</div>
		</div>
		);
	}

}

Settings.propTypes = {
  success: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	account_get: PropTypes.func.isRequired,
	email_change: PropTypes.func.isRequired,
	password_change: PropTypes.func.isRequired,
	user_delete: PropTypes.func.isRequired,
	account: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success,
	account: state.account
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps,
    { account_get, email_change, password_change, user_delete }
  )
) (Settings);
