import {
  GET_COMPETITION,
  GET_COMPETITIONS,
  COMPETITION_LOADING,
} from '../actions/types';

const initial_state = {
  competitions: null,
  competition: null,
  competitions_loading: false
};

export default function (state = initial_state, action) {

  switch (action.type) {
    case COMPETITION_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_COMPETITIONS:
      return {
        ...state,
        competitions: action.payload,
        loading: false
      };
    case GET_COMPETITION:
      return {
        ...state,
        competition: action.payload,
        loading: false
      };
    default: return state;
  }

}
