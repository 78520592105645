import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Moment from 'react-moment';

import { CompetitionDescription } from '../CompetitionDescription';

import { withTranslation } from 'react-i18next';
import {
    competition_status_string, competition_type_string
} from '../../../actions/competitionsActions';

class CompetitionInformation extends Component {

  render () {
    let { competition } = this.props;

    let competition_type = competition_type_string (competition.competition_type);
    let competition_status = competition_status_string (competition.status);

    const { t } =this.props;

    return (
    <div className="mb-5">
    <div className="card competition-card bg-gray">
      <div className="row no-gutters">
        <div className="col-lg-4 card-img-holder">
          <img src={`/api/uploads/competitions/${competition._id["$oid"]}/${competition.logo}`} className="card-img" alt="image" />
        </div>
        <div className="col-lg-8" align="left">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <p className="font-20px"><strong>Type: </strong>{ competition_type }</p>
              </div>
              <div className="col-sm-6">
                <p className="font-20px"><strong>Status: </strong>{ competition_status }</p>
              </div>
              <div className="col-sm">
              </div>
            </div>
            <hr></hr>
            <h3>{t ('venue_name')}: { competition.venue_name }</h3>
            <h3>{t ('address')}: { competition.address.text }</h3>

            <h5>{t ('start_date')}: <Moment utc={true} format={t ('day_month_year')}>{ competition.start_date["$date"] }</Moment> </h5>
            <h5>{t ('end_date')}: <Moment utc={true} format={t ('day_month_year')}>{ competition.end_date["$date"] }</Moment> </h5>

            <CompetitionDescription description={competition.description} />
          </div>
        </div>
      </div>
    </div>
    </div>
    );
  }

}

CompetitionInformation.propTypes = {
  competition: PropTypes.object.isRequired
};

export default withTranslation ()(CompetitionInformation);
