import React, { Component } from 'react';
import { BrowserRouter as  Router, Route, Switch, Redirect }  from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import store from './store';

import Landing from './components/Landing';
import Authentication from './components/Authentication';

// dashboard
import Dashboard from './components/dashboard/Dashboard';
import Competitions from './components/dashboard/Competitions';
import Competition from './components/dashboard/competition/Competition';
import AthleteRegistrationInput from './components/dashboard/competition/AthleteRegistrationInput';
import TeamRegistrationInput from './components/dashboard/competition/TeamRegistrationInput';
import CompetitionRegistrationPassword from './components/dashboard/competition/CompetitionRegistrationPassword';
import Settings from './components/dashboard/Settings';

// checkout
import CheckoutSuccess from './components/checkout/Success';
import CheckoutCancel from './components/checkout/Cancel';

// other
import NotFound from './router/NotFound'
import PrivateRoute from './router/PrivateRoute';

import { user_token_check } from './actions/authActions';

user_token_check (store);

class App extends Component {

  render () {
    return (
      <Provider store= { store }>
        <Router>
          <div className="App">
            <Switch>
              {/* Competitions */}
              <PrivateRoute exact path="/" component={ Dashboard } />
              <PrivateRoute exact path="/competitions" component={ Competitions } />
              <PrivateRoute exact path="/competitions/:id/info" component={ Competition } />
              <Route exact path="/competitions/:id/registration/password" component = { CompetitionRegistrationPassword}/>
              <Route exact path="/competitions/:id/registration" component={ AthleteRegistrationInput } />
              <Route exact path="/competitions/:id/registration/team" component={ TeamRegistrationInput } />

              {/* Checkout */}
              {/* TODO: avoid manual access */}
              <PrivateRoute exact path="/competitions/:id/registration/success" component={ CheckoutSuccess } />
              <PrivateRoute exact path="/competitions/:id/registration/cancel" component={ CheckoutCancel } />

              {/* Dashboard */}
              <PrivateRoute exact path="/settings" component={ Settings } />

              <Route exact path='/auth' component={ Authentication } />

              {/* Other */}
              <Route exact path='/404' component={ NotFound } />
              <Redirect to="/404" />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }

}

export default App;
