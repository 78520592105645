import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from './common/Alert';

// my actions
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { account_clear_current } from '../actions/accountActions';
import { user_logout } from '../actions/authActions';

class Navbar extends Component {
  constructor () {
    super ();
    this.changeLanguage = this.changeLanguage.bind (this);    
  }

  onLogOutClick (e) {
    e.preventDefault ();
    this.props.account_clear_current ();
    this.props.user_logout ();
  }

  changeLanguage (lng) {
    i18n.changeLanguage (lng);
  }

  render () {
    let { isAuthenticated } = this.props.auth;

    const { t } = this.props;

    const authLinks = (
      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <a className="navbar-brand dropdown-toggle text-white"
          data-toggle="dropdown" href="#" role="button" aria-haspopup="true" target="_top">
            {t ('my_account')}
          </a>
          <div className="dropdown-menu dropdown-menu-right main-purple-background">
            <a className="font-20px dropdown-item text-white" href="/" target="_top">
              {t ('profile')} <i className="fas fa-user float-right"></i>
            </a>
            <a className="font-20px dropdown-item text-white" href="/" target="_top">
              {t ('dashboard')} <i className="fas fa-home float-right"></i>
            </a>
            <a className="font-20px dropdown-item text-white" href="/settings" target="_top">
              {t ('settings')} <i className="fas fa-cog float-right"></i>
            </a>

            <div className="dropdown-divider"></div>

            <div className="nav-item">
              <a href="/" onClick={ this.onLogOutClick.bind (this) }
              className="font-20px dropdown-item text-white">
                {t ('logout')} <i className="fas fa-sign-out-alt float-right"></i>
              </a>
            </div>
          </div>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="navbar-brand text-white" to="/register" target="_top">{t ('sign_up')}</Link>
        </li>
        <li className="nav-item">
          <Link className="navbar-brand text-white" to="/login" target="_top">{t ('login')}</Link>
        </li>
      </ul>
    );

    return (
      <div className="sticky-top">
        <nav className="navbar navbar-expand-lg navbar-dark main-purple-background">
          <div className="container-fluid">
            <a className="navbar-brand" href="/" target="_top">
              timeCapp
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="mobile-nav">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a className="navbar-brand text-white" href="https://timecapp.app" target="_top">{t ('about')}</a>
                </li>
                <li className='nav-item'>
                  <button className='navbar-brand btn btn-outline-light text-white' onClick={() => this.changeLanguage ("en")}>EN</button>
                </li>
                <li className='nav-item'>
                  <button className='navbar-brand btn btn-outline-light text-white' onClick={() => this.changeLanguage ("es")}>ES</button>
                </li>
                {/* <li className="nav-item">
                  <a className="navbar-brand text-white" href="/projects" target="_top">Projects</a>
                </li>
                <li className="nav-item">
                  <a className="navbar-brand text-white" href="/blog" target="_top">Blog</a>
                </li>

                <li className="nav-item">
                  <a className="navbar-brand text-white" href="/contact" target="_top">Contact</a>
                </li> */}
              </ul>
              
              { isAuthenticated ? authLinks : guestLinks }
            </div>
          </div>
        </nav>

        <Alert />
      </div>
    );
  }

}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  user_logout: PropTypes.func.isRequired,
  account_clear_current: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default compose (
  withTranslation (),
  connect (
    mapStateToProps, { user_logout, account_clear_current }
  )
)
(Navbar);
