import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// redux
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// my components
import Footer from '../Footer';
import Navbar from '../Navbar';

// my actions
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { checkout_success } from '../../actions/checkoutActions';
import { competition_get_info } from '../../actions/competitionsActions';

class CheckoutSuccess extends Component {

  constructor () {
    super ();

    this.state = {
      mobile: false
    }
  }

  componentDidMount () {
    let competition = this.props.match.params.id;

    let params = new URLSearchParams (this.props.location.search);

    let user = params.get ("user");
    let phase = params.get ('phase');
    let division = params.get ('division');
    let teams = params.get ('teams');
    let mobile = params.get ("mobile") == "True";
    let created = params.get ("created");

    this.setState({
      mobile: mobile
    })

    if (competition) {
      // fetch general competition's information
      this.props.competition_get_info (competition, null);

      // request successful checkout
      this.props.checkout_success (
        user, competition, phase, division, teams, created
      )
    }
  }

  render () {
    let competition = this.props.match.params.id;

    const { t } = this.props;

    return (
    <div>
      <Navbar />

      <div className="register-area d-flex">
        <div className="register-side-content bg-img success-bg"></div>

        <div className="register-content-wrapper d-flex align-items-center">
          <div className="register-content">
            <h1 className="main-purple">{t('success')}</h1>
            <hr></hr>
            <p>{t('successfully_registered')}</p>
            <p>{t('email_sent')}</p>
            <br></br>

            {
              this.state.mobile 
              ? (
                <p><a href="timecapp://success" target="_blank" className="main-purple">Tap here to return to the app.</a></p>
              )
              : (
                <p><Link to={`/competitions/${competition}/info`} target="_top" className="main-purple">Click here to return to the competition's page.</Link></p>
              )
            }

            <br></br>
            <br></br>

            <hr></hr>

            <br></br>

            <h4>{t('contact_us_at')}</h4>
            <h3 className="main-purple">timecappstadium@gmail.com</h3>
          </div>
        </div>
      </div>

      <Footer />
    </div>
    );
  }
}

CheckoutSuccess.propTypes = {
  errors: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
  checkout_success: PropTypes.func.isRequired,
  competition_get_info: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success
});

export default compose (
  withTranslation (), 
  connect (
    mapStateToProps,
    { checkout_success, competition_get_info }
  )
) (CheckoutSuccess);
