import React, { useState, useMemo } from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Select from 'react-select'
import countryList from 'react-select-country-list'

const CountrySelector = ({
  name,
  placeholder,
  disabled = false,
  value,
  error,
  onChange
}) => {
  const [local, setLocal] = useState ('');
  const options = useMemo (() => countryList ().getData (), []);

  const changeHandler = selected => {
    setLocal (selected);
    onChange (selected);
  }

  return (
    <div className="form-group">
      <Select 
        className={ classnames ('form-control-lg', {
          'is-invalid': error
        })}
        isDisabled={disabled}
        placeholder={ placeholder }
        name={ name }
        value={ local }
        onChange={ changeHandler }
        options={ options }
      />
      {/* { info && <small className="form-text text-muted">{info}</small> } */}
      { error && <div className="invalid-feedback">{error}</div> }
    </div>
  );
}

CountrySelector.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

CountrySelector.defaultProps = {
  name: "country",
  placeholder: "Select country"
};

export default CountrySelector;
